import { useParams, Link, useLocation } from "react-router-dom";
import React, { useState, useEffect, useRef } from "react";
import DocList from "../components/side/DocList";
import axios from "axios";
import "./css/board.css";
import {BsPin} from "react-icons/bs"
import Pagination from "./Pagination";
import {GiHamburgerMenu} from "react-icons/gi"
import { MdLocalPrintshop } from "react-icons/md";


const Docs = () => {
    const [data, setData] = useState([]);

    const [isActive, setActive] = useState(false);
    const [selectForm, setSelectForm] = useState([]);
    const [parent, setParent] = useState("");
    const [docName, setDocName] =useState("");
    const [num, setNum] = useState(0);
    const [lev, setLev] = useState(0);
    const [sidebar, setSidebar] = useState(false);
    const [search, setSearch] = useState(null);
    const [boardN, setBoardN] = useState("");
    const [boardNum, setBoardNum] = useState();
    const [filterData, setfilterData] = useState([]);
    const [forms, setForms] = useState([]);
    const [formGroup, setFormGroup] = useState();
    const [formNo, setFormNo] = useState();
    const [isEmpty, setEmpty] = useState(false);
    const [isPage, setIsPage] = useState(false);
    const [isloggedIn, setLoggedIn] = useState(false);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const offset = (page - 1) * limit;
    const { state } = useLocation();
    const toggleText = () => {
      setActive(!isActive);
    };
    const onChangeSearch = (e) => {
      e.preventDefault();
      setSearch(e.target.value);
    };
  
    const { status } = useParams();
    console.log("status is", status);
    useEffect(() => {


      let type = window.location.pathname.split("/")[1];
      let num = window.location.pathname.split("/")[2];
      console.log(type,num);
      let name="";
      if (type === "docs"){
        switch(num){
          case "1"  : 
            name  = "[결재 상신] 미완료";
            break;
          case "2" :
            name = "[결재 상신] 보류";
            break;
          case "3" :
            name= "[결재 상신] 반려";
            break;
          case "4" :
            name= "[결재 상신] 완료";
            break;
          default :
            name= "[결재 상신] 임시 저장";

        }
      }

      setDocName(name);


         axios.get("https://gw.thegmmedical.com:5003/api/getdoc/"+status).then((response) => {
          console.log("hihihihi", response.data);
        setData(response.data);
        setfilterData(response.data);
        if (response.data.length === 0) {
          setEmpty(true);
        } else {
          setEmpty(false);
          if (response.data.length <= 10) {
            setIsPage(false);
          } else {
            setIsPage(true);
          }
        }
      });
  
      axios.get('https://gw.thegmmedical.com:5003/api/getallforms')
      .then(function (response){
      console.log("hey result is!!!!!!!!!!!!", response.data);
      setForms(response.data);
      
      //setLine(response.data[1]);
      
      
      })
      .catch(function (error){
      console.log(error);
      })
      .then(function (){
      //always executed
      });
      
    }, []);


   




 
  const addBookmark = () => {
    var result = window.confirm("즐겨찾기 게시판에 추가하시겠습니까?");
    if(result){
      const data ={
        boardName: boardN,
        boardNum : boardNum
      }
      console.log(data);
      axios.post('https://gw.thegmmedical.com:5003/api/add_bookmark', data).then(function (response){
        console.log(response);
      })
      .catch(function (error){
        console.log(error);
      })
      .then(function (){
        //always executed
      });
    }
  };
  
  
  const onSearch = (e) => {
    e.preventDefault();
    setPage(1);

    console.log("formNo:", formNo);
    if (formNo !==undefined){

      const form_filtered = data.filter((itemList) => {
        return itemList.form_no==formNo;
      });
      console.log("filtered...", form_filtered);
      const filtered = form_filtered.filter((itemList) => {
        return itemList.subject.toUpperCase().includes(search.toUpperCase());
      });
      setfilterData(filtered);
      if (filtered.length === 0) {
        setEmpty(true);
        setIsPage(false);
      } else {
        setEmpty(false);
        if (filtered.length <= 10) {
          setIsPage(false);
        } else {
          setIsPage(true);
        }
      }
    }else{
      
      const filtered = data.filter((itemList) => {
        return itemList.subject.toUpperCase().includes(search.toUpperCase());
      });

      setfilterData(filtered);

      if (filtered.length === 0) {
        setEmpty(true);
        setIsPage(false);
      } else {
        setEmpty(false);
        if (filtered.length <= 10) {
          setIsPage(false);
        } else {
          setIsPage(true);
        }
      }
    }

    
  };



  const handleSelectChange = (e) => {
    //const name = e.target.name 
    //const value = e.target.value 
    const { name, value} = e.target;
    console.log("name is ", name, "value is", value);
    setFormGroup(value);
    if (value === "all"){
      setFormNo();
      setSelectForm();
    }else{
      var index;
      for (let i=0;i<forms.length;i++){
        console.log("selected", forms[i], value);
        if (forms[i].fgroup_no == value){
          index = i;
          console.log("selected,,,,", forms[i].fgroup_no);
        }
      }
      

      axios.get('https://gw.thegmmedical.com:5003/api/getformlist/'+value,).then(function (response){
        console.log(response.data);
        setSelectForm(response.data);
        setFormNo(response.data[0].form_no);
      })
      .catch(function (error){
        console.log(error);
      })
      .then(function (){
        //always executed
      });

        //setSelectForm(forms[index].list);
    }
   

  };
  const handleFormChange = (e) => {
    //const name = e.target.name 
    //const value = e.target.value 
    const { name, value} = e.target;

    var index;
    for (let i=0;i<selectForm.length;i++){
      console.log("selected", selectForm[i], value);
      if (selectForm[i].form_no == value){
        index = i;
        console.log("selected,,,,", selectForm[i].form_no);
      }
    }
    console.log(selectForm[index]);

  

    setFormNo(value);

  
  };
  

  const addBookmarkPage = () => {
    var result = window.confirm("메뉴 바로가기에 추가하시겠습니까?");
    if(result){

      const data ={
        name: docName,
        num : '',
        link : window.location.pathname,
        bookmark : docName+" 결재함"
      }
      
      console.log(window.location.pathname)
      console.log(data);
      axios.post('https://gw.thegmmedical.com:5003/api/add_bookmark_page', data)
      .then(function (response){
        console.log(response);
        alert("메뉴 바로가기에 추가되었습니다.");

      })
      .catch(function (error){
        console.log(error);
      })
      .then(function (){
        //always executed
      });
    }
  };

  const deleteDoc = (no) => {

    var confirm = window.confirm("삭제하시겠습니까??");
    if (confirm){

      const msg ={
        doc_no : no
      } 
      

      axios.post('https://gw.thegmmedical.com:5003/api/delete_doc', msg)
      .then(function (response){
        console.log(response);
        alert("삭제되었습니다.");
        let temp = filterData;

        var filtered = temp.filter(function(el) { return el.doc_no !=  no; }); 

        setfilterData([...filtered]);


        //window.location.reload(true);

      })
      .catch(function (error){
        console.log(error);
      })
      .then(function (){
        //always executed
      });
    }

    
  }

  const renderStatus = (status) => {
    switch (status) {
        case 0:
            return "미승인";
        case 1:
            return "미승인" ;
        case 2:
            return "보류";
        case 3:
            return "반려";
        case 4:
            return "승인";
        default:
            return "미승인";
    }
};

  return (
    <div className="wrapper">
    <div className="sidebar-icon" style={{margin:"0 auto"}} onClick={()=>{if (sidebar === false){document.getElementsByTagName("main")[0].style.overflow="hidden"}else{document.getElementsByTagName("main")[0].style.overflow="auto"} setSidebar(!sidebar);}}>
        <GiHamburgerMenu/>
      </div>
      {sidebar && (
        <div className="side-mobile-menu">
        <DocList/>
        </div>
      )}
     <div className="content-wrap">
     
      
      <div className="outer_wrapper doc" style={{justifyContent:"flex-start"}}>
        <div className="side-menu">
        <DocList changeBoard={(value)=>{setfilterData(value);
        if (value.length === 0) {
          setEmpty(true);
        } else {
          setEmpty(false);
          if (value.length <= 10) {
            setIsPage(false);
          } else {
            setIsPage(true);
          }
        }}} changeBoardN={(value)=>{setBoardN(value);}} changeBoardNum={(value)=>{setBoardNum(value);}}/>
        </div>
        <div className="board_wrap">
    
     
        <div className="">
        
        <div className="board_wrapper">
          
          <div className="">
          
            <div id="content" className="content">  
            <form name="form1" id="form1" method="post">
              <div className="board_top">
                <div className="btns" style={{alignItems:"center"}}>

                  <div className="btn" style={{display:"flex", alignItem:"center", padding:"5px"}}  onClick={()=>addBookmarkPage()}><BsPin/></div>

                  <div style={{marginLeft:"5px"}}>{docName} 결재함</div>

                </div>


                      
                              <div className="bt_srch">
                                <div className="bts_slct">
                                <select name="form_group" className="formInput" value={formGroup ||''}
                                  onChange={handleSelectChange}>    
                                  <option value="all">양식 전체</option>
                                  {forms.map((element, index)=>(
                                  <option value={element.fgroup_no} className={element.fgroup_no}>{element.fgroup_name}</option>
                                ))}
                              </select>

                              {selectForm !==undefined &&
                              selectForm.length>0 && (
                              <select name="form_no" className="formInput" value={formNo ||''} style={{marginLeft:"10px"}} onChange={handleFormChange}> 

                              
                                  {selectForm.map((element, index)=>(
                                  <option value={element.form_no} className={element.form_no}>{element.form_name}</option>
                                ))}
                                
                                
                              </select> 
                              )}  
                                </div>
                             
                                <div className="bt_input">
                                  <input
                                    type="text"
                                    id="search_content"
                                    name="search_content"
                                    value={search || ''}
                                    placeholder="검색어를 입력하세요"
                                    onChange={onChangeSearch}
                                  />
                                </div>

                                <button
                                  type="submit"
                                  className="search_btn"
                                  value="검색"
                                  onClick={onSearch}
                                >
                                  검색
                                </button>
                              </div>
                          </div>
                          </form>
                          <div className="doc_list">
                         
                          
                  <table class="table table-hover bd-b" style={{tableLayout:"fixed"}}>
									<thead class="thead-dark">
									<tr class="tx-center">
										<th class="wd-80 xl-hidden">종결</th>
										<th class="wd-150 xl-hidden">양식명</th>
                    <th class="wd-150 xl-hidden" style={{width:"5%"}}>문서번호</th>

                    <th class="wd-150 xl-hidden" style={{width:"15%"}}>작성자</th>
										<th class="wd-120 xl-hidden" style={{width:"35%"}}>제목</th>
										<th class="wd-80 xl-hidden">작성일자</th>
                    <th class="wd-80 xl-hidden">결재자</th>
                    <th class="wd-80 xl-hidden">결재일자</th>
                    {status == 0 && <th class="wd-80 xl-hidden">삭제</th>}
									</tr>
									</thead>
									<tbody>
             
                                   
                                {filterData
                                  .slice(offset, offset + limit)
                                  .map(
                                    ({
                                      doc_no, status, form_name, user_name, subject, submit_date, last_approver, last_date, form_no, print
                                    }) => (
                                      
                                        <tr>                                     

                                        <td>
                                       {renderStatus(status)}
                                       </td>
                                      
                                 
                                        <td>
                                        {form_name}
                                        </td>
                                        <td style={{width:"5%"}}>
                                        {doc_no}
                                        </td>
                                        <td style={{width:"15%"}}>
                                        {user_name}
                                        </td>

                                        <td style={{width:"100%", display:"block"}}>
                                          {status==0 ? 
                                          <Link to ={"/doc_w/"+form_no} state={{doc_no: doc_no, form_no: form_no}}>{subject}</Link>
                                           :
                                          <Link to={"/docview"}  state={{doc_no: doc_no, href:window.location.pathname}}>{subject}</Link>
                                        }
                                        {print !== null && ( <MdLocalPrintshop style={{marginLeft:"5px", color:"#bdd3ff"}}/> )}
                                        </td>
                                        <td>
                                        {submit_date}
                                        </td>
                                        <td>
                                          {last_approver}
                                        </td>
                                        <td>
                                          {last_date}
                                        </td>

                                        {status == 0 && <td class="wd-80 xl-hidden"><button style={{width:"50px"}} onClick={()=>deleteDoc(doc_no)}>삭제</button></td>}

                                      </tr>
                                      
                                     
                                    )
                                  )}

									</tbody>
								</table>

                {isEmpty && (
                  <div style={{width:"100%", padding:"50px 0", textAlign:"center", borderBottom:"1px solid #ddd"}}>
                  게시물이 없습니다.
                  </div>
                )}
                            <table>
                              <colgroup>
                                <col className="m_dp_n table_no" />
                                <col className="" />
                                <col className="ta_l" />
                                <col className="m_dp_n table_date" />
                              </colgroup>
                              {/*<thead>
                        <tr>
                            <th className="m_dp_n table_no" scope="col">NO.</th>
                            <th scope="col"></th>
                            <th className="ta_l" scope="col">제목</th>
                            <th className="m_dp_n table_date" scope="col">날짜</th>
                        </tr>
                    </thead>*/}
                              <tbody>
                               
                              </tbody>
                            </table>
                            <div style={{width:"100%",textAlign:"right"}}>
                    {isloggedIn && (
                      <Link to="write"
                        style={{
                          padding: "10px 30px",
                          marginTop: "20px",
                          backgroundColor: "#555",
                          color: "white",
                          textAlign: "center",
                          position:"relative",
                          display:"inline-block",
                          width:"105px",
                          cursor: "pointer",
                        }}
                      >
                        글쓰기
                      </Link>
                    )}
                    </div>
                  </div>
                  {isPage && (
                    <div className="paging">
                      <Pagination
                        postsPerPage={limit}
                        totalPosts={filterData.length}
                        page={page}
                        paginate={setPage}
                      ></Pagination>
                      {/*<Pagination
                    total={data.length}
                    limit={limit}
                    page={page}
                    setPage={setPage}
                    />*/}
                    </div>
                  )}
                      </div>
                  </div>
          
        </div>
      </div>
            
            
                
                
                
        </div>

                </div>
     </div>
    </div>

  );
};

export default Docs;