import { NavLink, Link,useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import React, { useState, useEffect, useRef } from "react";
import MailNav from "../components/side/MailNav";
import './css/view.css';
import { GiHamburgerMenu } from "react-icons/gi";
import DocList from "../components/side/DocList";
import Sign from "../components/Sign";
import { Tooltip } from "react-tooltip";
import { BiInfoCircle } from "react-icons/bi";
import { Sheet, Op, Selection, colors } from "@fortune-sheet/core";
import { Workbook, WorkbookInstance } from "@fortune-sheet/react";
import "@fortune-sheet/react/dist/index.css"
import LeaveView from "../components/LeaveView";
import OTView from "../components/OTView";
import CancelLeaveView from "../components/CancelLeaveView";


import Order from "./forms/OrderSign";
import Confirm from "./forms/ConfirmSign";
import Request from "./forms/RequestSign";
import Repair from "./forms/RepairSign";
import Rent from "./forms/RentSign";
import Service from "./forms/ServiceSign";
import Endoscope from "./forms/EndoscopeSign";
import Inspect from "./forms/InspectSign";
import Acquire from "./forms/AcquireSign";
import HR from "./forms/HRSign";
import Purchase from "./forms/PurchaseSign";
import LogiOrder from "./logistics/OrderSign";

import Expenditure from "./common/ExpenditureSign";
import Receipt from "./common/ReceiptSign";

import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";

axios.defaults.withCredentials = true; 
const initialValues = {
  subject: "",
  user_no: "",
  user_name: "",
  form_group :"",
  form_no:"",
  form_name:"",
  cc: "",
  desc:"",
  recipient:"",
  expire:"1",
  security:"1",
 
};


const DocSign = () => {
  const { doc_no } = useParams();
    console.log(doc_no);
  const [toggle, setToggle] = useState(true);

  const [line, setLine] = useState();
  const [sidebar, setSidebar] = useState(false);
  
  const [showSign, setShowSign] = useState(false);
  const [user, setUser] = useState([]);
  const [data, setData] = useState([]);
  const [orderNo, setOrderNo]= useState();
  const [lineList, setLineList] = useState([]);
  const [lineName, setLineName] = useState("");
  const [lineNo, setLineNo] = useState();
  const [__html, setHtml] = useState("");
  const [name, setname]= useState("");
  const [userNo, setUserNo]= useState();
  const [, updateState] = React.useState();
  const [divv, setDivv] = useState();
  const [fileDiv, setFileDiv] = useState();
  const [loggedInUser, setLoggedInUser] = useState([]);

  //const [shData, setShData]= useState();
  const [customName, setCustomName] = useState("");
  const [absent, setAbsent] = useState([]);

  const [isOpen, setIsOpen] = useState(false);
  const [link, setLink] = useState("");

  const toggleOverlay = () => {
    setIsOpen(!isOpen);
  };
  function formatBytes(bytes, decimals = 2) {
    if (!+bytes) return '0 Bytes'
  
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  
    const i = Math.floor(Math.log(bytes) / Math.log(k))
  
    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
  }
  
  var shData=[];
  const navigate = useNavigate();
  function nextChar(c) {
    return String.fromCharCode(c.charCodeAt(0) + 1);
  }
  const itscell2 = {
    name: "Sheet1",
    config: {
      
     
      
      columnlen: {
        "0": 131,
        "2": 131,
        "3": 131,
        "4": 131,
        "5": 131,
        "6": 131,
        "7": 131,
        "8": 131,
     
      },
      
   
    },
    id: "0",
    zoomRatio: 1,
    order: "0",
    column: 8,
    row: 68,
    status: 1,
    ch_width: 2361,
    rh_height: 936,
    celldata:[],
    luckysheet_select_save: [
      {
        left: 741,
        width: 138,
        top: 796,
        height: 19,
        left_move: 741,
        width_move: 138,
        top_move: 796,
        height_move: 19,
        row: [33, 33],
        column: [6, 6],
        row_focus: 33,
        column_focus: 6
      }
    ],
    /*calcChain: [
      {
        r: 0,
        c: 3,
        id: "0",
        func: [true, 3, "=Formula!A1+Formula!B1"],
        color: "w",
        parent: null,
        chidren: {},
        times: 0
      }
    ],*/
    scrollLeft: 0,
    scrollTop: 0
  };
  useEffect(()=>{

    axios.get('https://gw.thegmmedical.com:5003/api/getuser')
    .then(function (response){
      setLoggedInUser(response.data[0]);
  
    })
    .catch(function (error){
    console.log(error);
    })
    .then(function (){
    //always executed
    });

    axios.get('https://gw.thegmmedical.com:5003/api/docview/'+doc_no)
    .then(function (response){
    console.log("hey result is!!!!!!!!!!!!", response.data[0]);
    setCustomName(response.data[0].custom_name);

    setData(response.data[0]);
    

    console.log("setCustomName", response.data[0].custom_name);

    if (response.data[0].form_data !== null && response.data[0].form_data !== "undefined" && response.data[0].form_data !==""){
      var excel_data = JSON.parse(response.data[0].form_data);
      excel_data.showGridLines = 0;
      setDivv(<div style={{width:"100vh", height:"600px"}}><Workbook data={[excel_data]} showToolbar={false} showFormulaBar={false} showSheetTabs={false} allowEdit={false} rowHeaderWidth={0} columnHeaderHeight={0}/></div>) ;

    }else{
      setDivv(<div></div>)
    }
    if (response.data[0].form_no === 1){
      console.log("1입니다");
      setDivv(<LeaveView docNo = {response.data[0].doc_no} userNo = {response.data[0].user_no}/>)
    }else if (response.data[0].form_no === 2){
      setDivv(<OTView docNo = {response.data[0].doc_no} userNo = {response.data[0].user_no}/>)
    }else if (response.data[0].form_no ===3){
      setDivv(<CancelLeaveView docNo = {response.data[0].doc_no} userNo = {response.data[0].user_no}/>)
    }
    axios.get('https://gw.thegmmedical.com:5003/api/getdocline/'+doc_no)
        .then(function (response){
        console.log("line list....", response.data);
        setLineList(response.data);

        //setLine(response.data[1]);
        
        
        })
        .catch(function (error){
        console.log(error);
        })
        .then(function (){
        //always executed
        });
    
    
    })
    .catch(function (error){
    console.log(error);
    })
    .then(function (){
    //always executed
    });

    axios.get('https://gw.thegmmedical.com:5003/api/getuser/'+doc_no)
    .then(function (response){
      console.log("user is", response.data);
    setUser(response.data[0]);
    var name = response.data[0].name + " " + response.data[0].job_name+" ("+response.data[0].d_name+")";
    setname(name);
    setUserNo(response.data[0].user_no);
    })
    .catch(function (error){
    console.log(error);
    })
    .then(function (){
    //always executed
    });

    axios.get('https://gw.thegmmedical.com:5003/api/checkabsent')
    .then(function (response){
      console.log("absent is", response.data);
      var user_nos = response.data.map(function(item) {
        return item['user_no'];
      })
    console.log("absent...", user_nos);
    setAbsent(user_nos);
    
  
    })
    .catch(function (error){
    console.log(error);
    })
    .then(function (){
    //always executed
    });


    const getFiles = async() =>{
      axios.get('https://gw.thegmmedical.com:5003/api/doc_files/'+doc_no)
      .then(function (response){
        console.log("hey dddddd is!!!!!!!!!!!!", response.data);
        var files = response.data;
        var file_divs =[];
        for (let i=0;i<files.length;i++){
          var pieces = files[i].filename.split(" ");
          var file_div = <div><a href={"https://gw.thegmmedical.com/uploads/doc/"+doc_no +"/"+files[i].filename} download>{files[i].filename} &nbsp;&nbsp;&nbsp;
              <span className="attachment_size">{formatBytes(files[i].size)} Bytes</span></a> &nbsp;&nbsp;&nbsp;
                  { (pieces[pieces.length-1] === "docx" || pieces[pieces.length-1] === "doc" || pieces[pieces.length-1] === "xlsx") && (
                  <button onClick={()=>{toggleOverlay(); setLink("https://gw.thegmmedical.com/uploads/doc/"+doc_no +"/"+files[i].filename);}}>오피스뷰어</button>
      )}
          </div>;
          file_divs.push(file_div);
        }
        setFileDiv(file_divs);
        //setFileList(response.data);
    
      })
      .catch(function (error){
      console.log(error);
      })
      .then(function (){
      //always executed
      });
    }


    getFiles();
}, []);






  const itscell = {
    name: "Sheet1",
    config: {
      
     
      
      columnlen: {
        "0": 131,
        "2": 131,
        "3": 131,
        "4": 131,
        "5": 131,
        "6": 131,
        "7": 131,
        "8": 131,
     
      },
      
   
    },
    id: "0",
    zoomRatio: 1,
    order: "0",
    column: 8,
    row: 68,
    status: 1,
    ch_width: 2361,
    rh_height: 936,
    celldata:[],
    luckysheet_select_save: [
      {
        left: 741,
        width: 138,
        top: 796,
        height: 19,
        left_move: 741,
        width_move: 138,
        top_move: 796,
        height_move: 19,
        row: [33, 33],
        column: [6, 6],
        row_focus: 33,
        column_focus: 6
      }
    ],
    /*calcChain: [
      {
        r: 0,
        c: 3,
        id: "0",
        func: [true, 3, "=Formula!A1+Formula!B1"],
        color: "w",
        parent: null,
        chidren: {},
        times: 0
      }
    ],*/
    scrollLeft: 0,
    scrollTop: 0
  };


  function popupwindow(url, title, w, h) {
    var left = (window.screen.width/2)-(w/2);
    var top = (window.screen.height/2)-(h/2);
    return window.open(url, title, 'toolbar=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width='+w+', height='+h+', top='+top+', left='+left);
  } 

  window.addEventListener('message', (event) => {
    if (event.data["line_no"] !== undefined){
      var line_no = event.data["line_no"];
      var line_name = event.data["line_name"];
      var line_list = event.data["line_list"];
      
      setLineNo(line_no);
      setLineName(line_name);
      setLineList(line_list);
    }
   console.log(line_list);

   
  });
////////////////////////////////////////////////
// EXCEL IMPORT
////////////////////////////////////////////////

function delLine(){
  setLineList([]);
  setLineName("");
  setLineNo();
}
const togglePopup = () => {

  setShowSign(!showSign);

  
  //
};
const openSign = () =>  {
  if (data.secured === 1){
    let pw = prompt("결재 암호를 입력하세요", "");
    if (pw === user.e_sign_pw){
      togglePopup();
    }else{
      alert("암호가 일치하지 않습니다.")
    }
    
  }else{
    togglePopup();
  }
}

  return (
    <div className="wrapper">
    <div className="sidebar-icon" style={{margin:"0 auto"}} onClick={()=>{if (sidebar === false){document.getElementsByTagName("main")[0].style.overflow="hidden"}else{document.getElementsByTagName("main")[0].style.overflow="auto"} setSidebar(!sidebar);}}>
        <GiHamburgerMenu/>
      </div>
      {sidebar && (
        <div className="side-mobile-menu">
        <DocList/>
        </div>
      )}
    <div className="content-wrap">
    
      
    <div className="outer_wrapper" style={{justifyContent:"flex-start", width:"100%"}}>
    <div className="side-menu">
      <DocList/>
    </div>
    

    <div className="main_content">

    {customName === "" && 
  showSign && absent.includes(loggedInUser.user_no)? (
  <Sign custom_name = {customName} docNo= {doc_no} formNo={data.form_no} subject= {data.subject} orderNo = {orderNo} decider = {user} line = {line} userNo = {userNo} closePopup={() => togglePopup()} />
  ) : null}
{showSign && !absent.includes(loggedInUser.user_no)? (
  <Sign custom_name = {customName} docNo= {doc_no} formNo={data.form_no} subject= {data.subject} orderNo = {orderNo} decider = {undefined} line = {line} userNo = {userNo} closePopup={() => togglePopup()} />
  ) : null
}
{customName === "hr" && 
   showSign && absent.includes(loggedInUser.user_no)? (
    <Sign custom_name = "hr" docNo= {doc_no} formNo={data.form_no} subject= {data.subject} orderNo = {orderNo} decider = {user} line = {line} userNo = {userNo} closePopup={() => togglePopup()} />
    ) : null}
  {showSign && !absent.includes(loggedInUser.user_no)? (
    <Sign custom_name = "hr" docNo= {doc_no} formNo={data.form_no} subject= {data.subject} orderNo = {orderNo} decider = {undefined} line = {line} userNo = {userNo} closePopup={() => togglePopup()} />
    ) : null
}

    <div>
            
      <h4 style={{padding:"0 10px"}}>문서 보기 </h4>
          <div style={{background:"#fff", padding:"0 10px"}}>
          {(lineList.length >0)  && (
            <div style={{display:"flex"}} className="line">


<table>

<tr className="name"><td rowSpan={5} className="type">작성자</td><td>{user.job_name}</td></tr>
<tr className="name"><td>{user.d_name}</td></tr>
<tr className="name"><td>{user.name}</td></tr>

<tr><td className=""><img src={user.sign_link} alt="preview" style={{width:"40px"}}/></td></tr>

<tr><td className="approved_date">{data.submit_date}</td></tr>
</table>

 {lineList.map((element, index)=> (
(() => {
if (element.status !==1 && element.user_no === loggedInUser.user_no && element.status !==5 && element.status !==4 && element.next == 1) {
return (
<table>
<tr className="name"><td rowSpan={5} className="type">{element.type}</td><td>{element.job_name}</td></tr>
<tr className="name"><td>{element.d_name}</td></tr>
<tr className="name"><td className=""><div>{element.name}</div>
</td>
</tr>
<tr>
<td>
<div style={{height:"40px"}}><button onClick={() => {openSign(); setLine(element.type);console.log("??");setOrderNo(element.order_no)}}>결재</button></div>
</td></tr>
<tr></tr>
</table>
)} else if(element.status === 4 && element.last!==1) {
  return (
    <table>
<tr className="name"><td rowSpan={5} className="type">{element.type}</td><td>{element.job_name}</td></tr>
<tr className="name"><td>{element.d_name}</td></tr>
<tr className="name"><td><div>{element.name}</div></td></tr>
<tr><td>
<div style={{height:"40px"}}>전결</div>
</td></tr>
<tr><td className="approved_date">{element.approved_date}</td></tr>

<tr></tr>
</table>)
        } else if(element.status === 3) {
          return (
          <table>
<tr className="name"><td rowSpan={5} className="type">{element.type}</td><td>{element.job_name}</td></tr>
<tr className="name"><td>{element.d_name}</td></tr>
<tr className="name"><td><div>{element.name}</div></td></tr>
<tr><td>
<div style={{height:"40px"}}>반려</div>
</td></tr>
<tr><td className="approved_date">{element.approved_date}</td></tr>

<tr></tr>
</table>)
        } else if(element.status === 2) {
          return (
          <table>
<tr className="name"><td rowSpan={5} className="type">{element.type}</td><td>{element.job_name}</td></tr>
<tr className="name"><td>{element.d_name}</td></tr>
<tr className="name"><td><div>{element.name}</div></td></tr>
<tr><td>
<div style={{height:"40px"}}>보류</div>
</td></tr>
<tr><td className="approved_date">{element.approved_date}</td></tr>

<tr></tr>
</table>)
        } else if (element.status != 1 && element.user_no === loggedInUser.user_no && element.next != 1) {
          return (
            <table>
            <tr className="name"><td rowSpan={5} className="type">{element.type}</td><td>{element.job_name}</td></tr>
            <tr className="name"><td>{element.d_name}</td></tr>
            
            <tr className="name"><td><div>{element.name}</div></td></tr>
            <tr><td>
            <div style={{height:"40px"}}>대기중</div>
            </td></tr>
            <tr></tr>
            </table>
          )        
        } else if(element.status === 0 && element.user_no !== loggedInUser.user_no && !absent.includes(element.user_no)) {
          return (
          <table>
<tr className="name"><td rowSpan={5} className="type">{element.type}</td><td>{element.job_name}</td></tr>
<tr className="name"><td>{element.d_name}</td></tr>

<tr className="name"><td><div>{element.name}</div></td></tr>
<tr><td>
<div style={{height:"40px"}}>대기중</div>
</td></tr>
<tr></tr>
</table>)
        } else if (element.status === 5 && element.user_no === loggedInUser.user_no) {
          return (
            <table>
<tr className="name"><td rowSpan={5} className="type">{element.type}</td><td>{element.job_name}</td></tr>
<tr className="name"><td>{element.d_name}</td></tr>

<tr className="name"><td><div>{element.name}</div></td></tr>
<tr><td>
<div style={{height:"40px"}}><button onClick={() => {openSign(); setLine(element.type);setOrderNo(element.order_no)}}>후결</button></div>
</td></tr>
<tr></tr>
</table>
          )

        
        } else if (element.status !==1 && absent.includes(element.user_no)) {
          return (
            <>
            <table>
<tr className="name"><td rowSpan={5} className="type">{element.type}</td><td>{user.job_name}</td></tr>
<tr className="name"><td>{element.d_name}</td></tr>

<tr className="name"><td><div>{user.name}</div></td></tr>
<tr><td>
<div style={{height:"40px"}}><button onClick={() => {openSign(); setLine(element.type); setUserNo(element.user_no);setOrderNo(element.order_no)}}>대결</button></div>
</td></tr>
<tr></tr>
</table>

<table>
<tr className="name"><td rowSpan={5} className="type">{element.type}</td><td>{element.job_name}</td></tr>
<tr className="name"><td>{element.d_name}</td></tr>

<tr><td className="slash"><div className="name"></div>

</td></tr>
<tr></tr>
</table>
</>
          )
        }
        else {
          return (
            <table>
<tr className="name"><td rowSpan={5} className="type">{element.type}</td><td>{element.job_name}</td></tr>
<tr className="name"><td>{element.d_name}</td></tr>

<tr className="name"><td><div>{element.name}</div></td></tr>

<tr><td>
<img src={element.sign_img} alt="preview" style={{width:"40px"}}/></td></tr>
<tr><td className="approved_date">{element.approved_date}</td></tr>
</table>
          )
        }
      })()

      ))}
      
            </div>
          )}
          <div style={{display:"flex", width:"100%", justifyContent:"space-between", flexDirection:"column"}}>
          

         
          <table className="form_write" style={{margin:"10px 0", width:"100%"}}>

      {/*<button onClick={()=>downloadEmployeeData()}>test...</button>*/}
      <tr className="table_row mobile">
<td class="table_title2" nowrap="">
  문서번호

</td>
  <td class="table_list2_left">
  <div className="input-group" style={{display:"flex", flexShrink:"0"}}>
  <span>{doc_no}</span>
  </div>
  </td>
</tr>
      <tr>
<td class="table_title2" nowrap="">
  제목
</td>
  <td class="table_list2_left">
  <div className="input-group">
  <span type="text" name="subject" className="formInput">{data.subject}</span>
  </div>
  </td>

  <td class="table_title2 pc" nowrap="">
  작성자
</td>
  <td class="table_list2_left pc">
  <div className="input-group" style={{display:"flex", flexShrink:"0"}}>
  <span>{data.user_name}</span>
  </div>
  </td>

</tr>      


<tr className="table_row mobile">
<td class="table_title2" nowrap="">
  작성자

</td>
  <td class="table_list2_left">
  <div className="input-group" style={{display:"flex", flexShrink:"0"}}>
  <span>{data.user_name}</span>
  </div>
  </td>
</tr>

<tr>
<td class="table_title2" nowrap="">
  파일
</td>
  <td class="table_list2_left">
  <div className="input-group">
  {fileDiv}
  </div>
  </td>


</tr>      



      <tr>
<td class="table_title2" nowrap="">
  양식분류
</td>
  <td class="table_list2_left">
  <div className="input-group" style={{display:"flex", flexShrink:"0"}}>
  <span type="text" name="subject" className="formInput">{data.form_name}</span>

  </div>
  </td>


  <Tooltip
        id="date_guide"
        place="bottom"
        >
          <div style={{display:"flex", flexDirection:"column", textAlign:"left"}}>
            <span>양식의 상태를 설정합니다.</span>
          </div>
        </Tooltip>


</tr>








<tr>
<td class="table_title2" nowrap="">
  결재라인
</td>
  <td class="table_list2_left">
  <div className="input-group">
  
  {lineName !=="" && (
    <div>
      <span>{lineName} </span> 
    </div>
  )}
  </div>
  </td>



</tr>


<tr>
<Tooltip
        id="cc"
        place="bottom"
        >
          <div style={{display:"flex", flexDirection:"column", textAlign:"left"}}>
            <span>문서참조자는 결재문서와 결재상태를 확인할 수 있습니다.</span>
          </div>
        </Tooltip>

<td class="table_title2" nowrap="">
  문서참조자<BiInfoCircle data-tooltip-id="cc" style={{marginLeft:"5px"}}/>
</td>
<td class="table_list2_left" >
        <div className="input-group">
        <span type="text" name="subject" className="formInput"></span>
        {data.cc !==undefined&&(
          JSON.parse(data.cc).map(item => (
    <div className="admin"><li key={item.id}>
      {item.text}
    </li>

    </div>
      ))
        )}
      </div>
     
		</td>
</tr>

<tr>
<td class="table_title2" nowrap="">
  문서수신자
</td>
<td class="table_list2_left">
        <div className="input-group">
        <span type="text" name="subject" className="formInput"></span>
        {data.recipient !==undefined&&(
          JSON.parse(data.recipient).map(item => (
    <div className="admin"><li key={item.id}>
      {item.text}
    </li>

    </div>
      ))
        )}
      </div>
     
		</td>
</tr>

<tr>

<td class="table_title2" nowrap="">
  보관기간
</td>
  <td class="table_list2_left">
  <div className="input-group" style={{display:"flex", flexShrink:"0"}}>
  <span type="text" name="subject" className="formInput">{data.expire}년</span>

  </div>
  </td>
  <td class="table_title2 pc" nowrap="">
  보안등급
</td>
  <td class="table_list2_left pc">
  <div className="input-group" style={{display:"flex", flexShrink:"0"}}>
  <span type="text" name="subject" className="formInput">{data.security}등급</span>

  </div>
  </td>

</tr>


<tr className="table_row mobile">
<td class="table_title2" nowrap="">
  보안등급
</td>
  <td class="table_list2_left">
  <div className="input-group" style={{display:"flex", flexShrink:"0"}}>
  <span type="text" name="subject" className="formInput">{data.security}등급</span>

  </div>
  </td>
</tr>




    </table>


    {data.type ==="custom" &&(
<div className="custom_form custom_form_view" style={{height:"600px", overflow:"scroll", position:"relative"}}>
  {/*<Workbook onOp={console.log} data={[initial]} onChange={(data)=>{console.log("changed, ", data);}} />*/}
 
  {(data.type ==="custom"  && data.custom_name==="order") && (
  <Order data={data.doc_no} line={lineList}/>)}

{(data.type ==="custom"  && data.custom_name==="confirm") && (
  <Confirm data={data.doc_no} line={lineList}/>)
}
{(data.type ==="custom"  && data.custom_name==="request") && (
  <Request data={data.doc_no} line={lineList}/>)
}
{(data.type ==="custom"  && data.custom_name==="repair") && (
  <Repair data={data.doc_no} line={lineList}/>)
}

{(data.type ==="custom"  && data.custom_name==="rent") && (
  <Rent data={data.doc_no} line={lineList}/>)
}
{(data.type ==="custom"  && data.custom_name==="service") && (
  <Service data={data.doc_no} line={lineList}/>)
}

{(data.type ==="custom"  && data.custom_name==="endoscope") && (
  <Endoscope data={data.doc_no} line={lineList}/>)
}

{(data.type ==="custom"  && data.custom_name==="inspect") && (
  <Inspect data={data.doc_no} line={lineList}/>)
}
{(data.type ==="custom"  && data.custom_name==="acquire") && (
  <Acquire data={data.doc_no} line={lineList}/>)
}
{(data.type ==="custom"  && data.custom_name==="hr") && (
  <HR data={data.doc_no} line={lineList}/>)
}
{(data.type ==="custom"  && data.custom_name==="purchase") && (
  <Purchase data={data.doc_no} line={lineList}/>)
}
{(data.type ==="custom"  && data.custom_name==="logi_order") && (
  <LogiOrder data={data.doc_no} line={lineList}/>)
}
{(data.type ==="custom"  && data.custom_name==="expenditure") && (
  <Expenditure data={data.doc_no} line={lineList}/>)
}
{(data.type ==="custom"  && data.custom_name==="receipt") && (
  <Receipt data={data.doc_no} line={lineList}/>)
}

</div>
    )}
     {data.type !=="custom" &&(<div className="custom_form custom_form_view" style={{height:"600px", overflow:"hidden", position:"relative"}}>{divv}</div>)} 


    </div>

   

    </div>


    

  
    </div>
  </div>
  </div></div>

</div>
  );
};

export default DocSign;
