import React, { useRef, useEffect, useState } from "react";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import { renderToString } from 'react-dom/server'
import { BsPin } from "react-icons/bs";
import iconv from "iconv-lite";
import { BsPencilFill } from "react-icons/bs";

import axios from "axios";
import moment from "moment";
import styled from 'styled-components'
import MailDraftsList from "../components/MailDraftsList";
import {GiHamburgerMenu} from "react-icons/gi";
// Toast 에디터
import { Editor } from '@toast-ui/react-editor';
import '@toast-ui/editor/dist/toastui-editor.css';
import 'tui-color-picker/dist/tui-color-picker.css';
import '@toast-ui/editor-plugin-color-syntax/dist/toastui-editor-plugin-color-syntax.css';
import colorSyntax from '@toast-ui/editor-plugin-color-syntax';
import AutoComplete from "../components/AutoCompleteMail";
import MailNav from "../components/side/MailNav";
import {FaAddressBook, FaPaperPlane} from "react-icons/fa";
import "./css/write.css";
import { useClickAway } from "@uidotdev/usehooks";


//import LargeFile from "./largeFile";
axios.defaults.withCredentials = true; 



const DropDownBox = styled.ul`
  display: block;
  width:475px;
  top:30px;
  position:absolute;
  
  padding: 8px 0;
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-top: none;
  border-radius: 0 0 5px 5px;
  box-shadow: 0 2px 2px rgb(0, 0, 0, 0.3);
  list-style-type: none;
  z-index: 9999;
`

const DropDownItem = styled.li`
  padding: 0 16px;

  &.selected {
    background-color: lightgray;
  }
`
/*
const FileInfo = ({ uploadedInfo }) => (
  <ul className="preview_info">
    {Object.entries(uploadedInfo).map(([key, value]) => (
      <li key={key}>
        <span className="info_key">{key}</span>
        <span className="info_value">{value}</span>
      </li>
    ))}
  </ul>
);*/

function useOutsideAlerter(ref, value, list) {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        console.log("recItem.....", value)

        if (value !== '') {
          const highestId = Math.max.apply(Math, list.map(function (element) {
            return element.id;
        }));
        let newId = 1; // default in case the array is empty
      
        if (highestId > 0) {
            // generate a new ID based off of the highest existing element ID 
            newId = (highestId + 1);
        }
        
          const item = { id: newId, text: value };
          const tempArray = [...list, item];
          return tempArray;
        }

      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
}

export default function TextEditor() {
  
  const editorRef = useRef();
  const [sidebar, setSidebar] = useState(false);
  const inputFileRef = useRef();
  const [boxes, setBoxes] = useState("");
  const [subject, setSubject] = useState("");
  const [links, setLinks]=useState([]);
  const [done, setDone]=useState(false);
  const [cnt, setCnt]= useState(0);
  const [dateTime, setDateTime] = useState("");
  const [show, setShow]=useState(false);
  const [draftNo, setDraftNo] = useState();
  const [fileArray, setArray] = useState([]);
  const [imgLink, setImgLink] = useState("");
  const [boardName, setBoardName] = useState("dfdfd");
  const { board } = useParams();
  const [fileList, setFileList] = useState([]);
  const [largeFiles, setLargeFiles] = useState([]);
  const [fileSize, setFileSize] = useState(0);
  const [peopleArray, setPeopleArray] = useState([]);
  const [inputValue, setInputValue] = useState('')
  const [isHaveInputValue, setIsHaveInputValue] = useState(false)
  const [dropDownList, setDropDownList] = useState(peopleArray)
  const [dropDownItemIndex, setDropDownItemIndex] = useState(-1)
  const location = useLocation();
  const [html, setHtml] = useState("");
  const [idEdit, setIdEdit] = useState("");
  const [editItem, setEditItem] = useState("");
  const [editRec, setEditRec] = useState(false);
  const [editCc, setEditCc] = useState(false);
  const [editBcc, setEditBcc] = useState(false);

  const [recItem, setRecItem] = useState("");
  const [recList, setRecList] = useState([]);

  const [addresses, setAddresses] = useState([]);

  const [isActive, setActive] = useState(false);
  const [sendEmail, setSendEmail] = useState("");
  const [fromName, setFromName] = useState("");

  const handleDragStart = () => setActive(true);
  const handleDragEnd = () => setActive(false);

  console.log("state....", location.state);


  const wrapperRef = useRef(null);
  //useOutsideAlerter(wrapperRef, recItem, recList);
  
  const [isOpen, setIsOpen] = React.useState(true);
  


  useEffect(() => {
    console.log("state....", location.state);

    axios.get('https://gw.thegmmedical.com:5003/api/get_send_mails')
    .then(function (response){
    console.log("eee is", response.data);
    setSendEmail(response.data[0].email);
    setFromName(response.data[0].name);
    setAddresses(response.data);
    //setRecList([{id:1, text:response.data[0].email}]);
  
    })
    .catch(function (error){
    console.log(error);
    })
    .then(function (){
    //always executed
    });
  
    
    if (location.state !== null){
      if (location.state.draft !==undefined)
      {
        let draft = location.state.draft;

        setSubject(draft.subject);
        editorRef.current?.getInstance().setHTML(draft.content);
        receiveFunc(draft.to_addr);
        setDraftNo(draft.draft_no);
      }
    }
   
    var string = "";
    axios.get('https://gw.thegmmedical.com:5003/api/get_mail_sign').then(function (response){
      console.log(response);
      let data=[];
      data = response.data[0];
      console.log("dataaaaaaaaaaaaaa", data);
      if (data!==undefined){
        if (data.in_use === 1){
        console.log(data.name);
        string =response.data[0].content.replace("{name}", data.name);
        string =string.replace("{tel}", data.tel);
        string =string.replace("{email}", data.email);
        string =string.replace("{job}", data.job_name);
        string =string.replace("{department}", data.d_name);
        string  = "<br></br>" + string;
        }
      }
      
      axios.get("https://gw.thegmmedical.com:5003/api/get_mail_footer").then((res) => {
        console.log("fooiter", res.data[0]);
        let footer="";

        if (res.data[0]!==undefined){

        if (res.data[0].in_use === 1){
          //footer = "<div style='border-top:1px solid #ddd'>"+res.data[0].content+"</div>";
          string = string + res.data[0].content;
        }
        }
           editorRef.current?.getInstance().setHTML(string);
      });
      
      
    })
    .catch(function (error){
      console.log(error);
    })
    .then(function (){
    });
    
    

    if (location.state !==null && location.state.org_to !==undefined){
      if (location.state.org_to.length>0){
        let checklist =  location.state.org_to;
        console.log("org_to", location.state.org_to);
        if (checklist[0].value==="userall"){
          checklist.shift();
        }
        
        let input=[];
        console.log("checklist,,,", checklist);
        for (let i=0;i<checklist.length;i++){
          if (checklist[i].indexOf('@') > -1)
          {
            
          }else{
            input.push(checklist[i]);
          }
        }
        
        const msg ={
          nos : input
        }
       
          axios.post('https://gw.thegmmedical.com:5003/api/get_emails_org', msg)
          .then(function (response){
            console.log(response);
            //window.location.reload(true);
            let temp =[];
            for (let i=0;i<checklist.length;i++){
              if (checklist[i].indexOf('@') > -1)
              {
                let item = { id: generateId_rec(), text: checklist[i] };
                temp = [...temp, item];
              }
            }
            for (let i=0;i<response.data.length;i++){
              let item = { id: generateId_rec(), text: response.data[i].email };
              temp = [...temp, item];
            }
            setRecList(temp);
            
      
          })
          .catch(function (error){
            console.log(error);
          })
          .then(function (){
            //always executed
          });
  
      }
      

    }
    
    if (location.state !==null && location.state.to !==undefined){
      let checklist =  location.state.to;
     
        if (checklist[0].value==="selectall"){
          checklist.shift();
        }
        const msg ={
          nos : checklist
        }
     

          axios.post('https://gw.thegmmedical.com:5003/api/get_emails', msg)
          .then(function (response){
            console.log(response);
            //window.location.reload(true);
            var temp =[];
            for (let i=0;i<response.data.length;i++){
              let item = { id: generateId_rec(), text: response.data[i].email };
              temp = [...temp, item];
            }
            setRecList(temp);
            
      
          })
          .catch(function (error){
            console.log(error);
          })
          .then(function (){
            //always executed
          });
    }
    
    if (location.state !==null && location.state.to_addr !==undefined){
      var temp =[];
      let item = { id: generateId_rec(), text: location.state.to_addr }; 
      temp = [...temp, item];
      setRecList(temp);
      
      const elements = document.querySelectorAll('.main_menu');
elements.forEach(element => {
  element.style.display = 'none';
});

    }
    if (location.state !==null && location.state.data !==undefined){
      console.log("data...", location.state.data);
      setSubject(location.state.data[0].subject);
      editorRef.current?.getInstance().setHTML(location.state.data[0].content);
      //let blob = await fetch(url).then(r => r.blob());
      var newF = [];
      async function createFile(url, filename){
        let response = await fetch(url);
        let data = await response.blob();
        let metadata = {
          type: 'image/jpeg'
        };
        let file = new File([data], filename, metadata);
        newF.push(file);
        setFileList(newF);
        // ... do something with the file or return it
      }
      
    
      for (let i=0;i<location.state.data.length;i++){
        if (location.state.data[i].filename!==undefined){
        var url = "https://gw.thegmmedical.com:5003/b_attach/"+location.state.data[0].filename;

        createFile(url, location.state.data[0].filename);
        }

      }
      

    }
    
   

  
  }, []);
  
  const toggleShow = () => {
    setShow(!show);
  };
  const onBtnClick = () => {
    /*Collecting node-element and performing click*/
    inputFileRef.current.click();
  };
  const subjectChange = (event) => {
    setSubject(event.target.value);
  };

  function formatBytes(bytes, decimals = 2) {
    if (!+bytes) return '0 Bytes'

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k))

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
  }

  function ValidateEmail(mail) 
  {
  if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail))
    {
      return (true)
    }
      //alert("You have entered an invalid email address!")
      return (false)
  }

  const removeFile = (i) => {
    setFileList([...fileList.filter((_, index) => index !== i)]);
    setFileSize(fileSize-fileList[i].size);
  };


  const generateId_rec = () => {
    console.log("rec................",recList);
     const highestId = Math.max.apply(Math, recList.map(function (element) {
            return element.id;
        }));
        let newId = 1; // default in case the array is empty

        if (highestId > 0) {
            // generate a new ID based off of the highest existing element ID 
            newId = (highestId + 1);
        }
        return newId;
  };

  function createNewToDoItem_rec() {

    if (recItem !== '') {
      const item = { id: generateId_rec(), text: recItem };
      console.log("item...........", item);
      const tempArray = [...recList, item];
      setRecList(tempArray);console.log(item);
    }
    setRecItem('');
    
  }
 
  function handleAdd_rec(e) {
    e.preventDefault();
    createNewToDoItem_rec();
  }

  const handleKeyPress_rec = e => {
    console.log("test test ", e.key);
    //console.log("keycode is " , e.key);
    if (e.key === 'Enter') {
      createNewToDoItem_rec();
    }else if (e.key === ' '){
      createNewToDoItem_rec();
    }else if (e.key === 'Tab'){
      createNewToDoItem_rec();
    }else if (e.key === 'Backspace' && recItem===""){
      let tmp = recList;
      tmp.splice(-1);
      setRecList([...tmp]);
    }
    /*e.target.addEventListener("blur" , e =>{
      
      createNewToDoItem_rec2(e.target.value);
    });*/
  };

  const handleKeyPressEdit_rec = e => {
    

    


    //console.log("keycode is " , e.key);
    if (e.key === 'Enter') {
      if (editItem !== '') {
        let tmp = recList;
        console.log("tmp...", tmp);
        //const item = { id: generateId_rec(), text: recItem };
  let objIndex = tmp.findIndex(obj => obj.id == idEdit.slice(3));
  
  //Log object to Console.
  console.log("Before update: ", tmp[objIndex])
  
  //Update object's name property.
  tmp[objIndex].text = editItem;
  
  //Log object to console again.
  console.log("After update: ", tmp[objIndex])
  
        const tempArray = [...tmp];
        setRecList(tempArray);
  
        
      }
      setEditItem('');
        setIdEdit('');
        setEditRec(false);
    }else if (e.key === ' '){
      if (editItem !== '') {
        let tmp = recList;
        console.log("tmp...", tmp);
        //const item = { id: generateId_rec(), text: recItem };
  let objIndex = tmp.findIndex(obj => obj.id == idEdit.slice(3));
  
  //Log object to Console.
  console.log("Before update: ", tmp[objIndex])
  
  //Update object's name property.
  tmp[objIndex].text = editItem;
  
  //Log object to console again.
  console.log("After update: ", tmp[objIndex])
  
        const tempArray = [...tmp];
        setRecList(tempArray);
  
        
      }
      setEditItem('');
        setIdEdit('');
        setEditRec(false);

    }else if (e.key === 'Tab'){
      if (editItem !== '') {
        let tmp = recList;
        console.log("tmp...", tmp);
        //const item = { id: generateId_rec(), text: recItem };
        let objIndex = tmp.findIndex(obj => obj.id == idEdit.slice(3));
  
  //Log object to Console.
  console.log("Before update: ", tmp[objIndex])
  
  //Update object's name property.
  tmp[objIndex].text = editItem;
  
  //Log object to console again.
  console.log("After update: ", tmp[objIndex])
  
        const tempArray = [...tmp];
        setRecList(tempArray);
  
        
      }
      setEditItem('');
        setIdEdit('');
        setEditRec(false);

    }
  
    /*e.target.addEventListener("blur" , e =>{
      
      createNewToDoItem_rec2(e.target.value);
    });*/
  };


  const handleKeyPressEdit_cc = e => {
    

    


    //console.log("keycode is " , e.key);
    if (e.key === 'Enter') {
      if (editItem !== '') {
        let tmp = ccList;
        console.log("tmp...", tmp);
        //const item = { id: generateId_rec(), text: recItem };
  let objIndex = tmp.findIndex(obj => obj.id == idEdit.slice(2));
  
  //Log object to Console.
  console.log("Before update: ", tmp[objIndex])
  
  //Update object's name property.
  tmp[objIndex].text = editItem;
  
  //Log object to console again.
  console.log("After update: ", tmp[objIndex])
  
        const tempArray = [...tmp];
        setCcList(tempArray);
  
        
      }
      setEditItem('');
        setIdEdit('');
        setEditCc(false);
    }else if (e.key === ' '){
      if (editItem !== '') {
        let tmp = ccList;
        console.log("tmp...", tmp);
        //const item = { id: generateId_rec(), text: recItem };
        let objIndex = tmp.findIndex(obj => obj.id == idEdit.slice(2));
  
  //Log object to Console.
  console.log("Before update: ", tmp[objIndex])
  
  //Update object's name property.
  tmp[objIndex].text = editItem;
  
  //Log object to console again.
  console.log("After update: ", tmp[objIndex])
  
        const tempArray = [...tmp];
        setCcList(tempArray);
  
        
      }
      setEditItem('');
        setIdEdit('');
        setEditCc(false);

    }else if (e.key === 'Tab'){
      if (editItem !== '') {
        let tmp = ccList;
        console.log("tmp...", tmp);
        //const item = { id: generateId_rec(), text: recItem };
        let objIndex = tmp.findIndex(obj => obj.id == idEdit.slice(2));
  
  //Log object to Console.
  console.log("Before update: ", tmp[objIndex])
  
  //Update object's name property.
  tmp[objIndex].text = editItem;
  
  //Log object to console again.
  console.log("After update: ", tmp[objIndex])
  
        const tempArray = [...tmp];
        setCcList(tempArray);
  
        
      }
      setEditItem('');
        setIdEdit('');
        setEditCc(false);

    }
  
    /*e.target.addEventListener("blur" , e =>{
      
      createNewToDoItem_rec2(e.target.value);
    });*/
  };


  const handleKeyPressEdit_bcc = e => {
    

    


    //console.log("keycode is " , e.key);
    if (e.key === 'Enter') {
      if (editItem !== '') {
        let tmp = bccList;
        console.log("tmp...", tmp);
        //const item = { id: generateId_rec(), text: recItem };
        let objIndex = tmp.findIndex(obj => obj.id == idEdit.slice(3));
  
  //Log object to Console.
  console.log("Before update: ", tmp[objIndex])
  
  //Update object's name property.
  tmp[objIndex].text = editItem;
  
  //Log object to console again.
  console.log("After update: ", tmp[objIndex])
  
        const tempArray = [...tmp];
        setBccList(tempArray);
  
        
      }
      setEditItem('');
        setIdEdit('');
        setEditBcc(false);
    }else if (e.key === ' '){
      if (editItem !== '') {
        let tmp = bccList;
        console.log("tmp...", tmp);
        //const item = { id: generateId_rec(), text: recItem };
        let objIndex = tmp.findIndex(obj => obj.id == idEdit.slice(3));
  
  //Log object to Console.
  console.log("Before update: ", tmp[objIndex])
  
  //Update object's name property.
  tmp[objIndex].text = editItem;
  
  //Log object to console again.
  console.log("After update: ", tmp[objIndex])
  
        const tempArray = [...tmp];
        setBccList(tempArray);
  
        
      }
      setEditItem('');
        setIdEdit('');
        setEditBcc(false);

    }else if (e.key === 'Tab'){
      if (editItem !== '') {
        let tmp = bccList;
        console.log("tmp...", tmp);
        //const item = { id: generateId_rec(), text: recItem };
        let objIndex = tmp.findIndex(obj => obj.id == idEdit.slice(3));
  
  //Log object to Console.
  console.log("Before update: ", tmp[objIndex])
  
  //Update object's name property.
  tmp[objIndex].text = editItem;
  
  //Log object to console again.
  console.log("After update: ", tmp[objIndex])
  
        const tempArray = [...tmp];
        setBccList(tempArray);
  
        
      }
      setEditItem('');
        setIdEdit('');
        setEditBcc(false);

    }
  
    /*e.target.addEventListener("blur" , e =>{
      
      createNewToDoItem_rec2(e.target.value);
    });*/
  };

  const display_rec = recList.map(item => (
    
    <div className="">
      {ValidateEmail(item.text) ? (
        <div className="user_list" style={{display: (idEdit != "rec"+item.id) ? 'flex' : 'none'}}>
        <li key={item.id} className="valid">
          {(item.name !==undefined && item.name !=="") && (<span><span>[</span>{item.name}<span>]</span></span>)} {item.text}
          </li>
          <span className="del_btn" onClick={() => {setIdEdit("rec"+item.id); setEditItem(item.text);}}><BsPencilFill/></span>
          <span className="del_btn" onClick={() => deleteItem_rec(item.id)}>X</span>
    </div>
      ) : (
        <div className="user_list unvalid" style={{display: (idEdit != "rec"+item.id)  ? 'flex' : 'none'}}>
        <li key={item.id}>
          {item.text}
          </li>
          <span className="del_btn" onClick={() => {setIdEdit("rec"+item.id); setEditItem(item.text);}}><BsPencilFill/></span>

          <span className="del_btn" onClick={() => deleteItem_rec(item.id)}>X</span>
    </div>
      )}

      <input value={editItem}
          onChange={e => {
            setEditItem(e.currentTarget.value);
          }}
          onKeyDown={handleKeyPressEdit_rec} style={{width:"auto", display: (idEdit == "rec"+item.id)  ? 'block' : 'none'}}></input>
    </div>
    
  ));
  const handleBlur_rec = e => {
    //console.log("keycode is " , e.key);
      createNewToDoItem_rec();
   
    /*e.target.addEventListener("blur" , e =>{
      
      createNewToDoItem_rec2(e.target.value);
    });*/
    
    
    
  };
  const deleteItem_rec = id => {
    setRecList(recList.filter(item => item.id !== id));
  };

  


  
  const [ccItem, setCcItem] = useState("");
  const [ccList, setCcList] = useState([]);

  const generateId_cc = () => {
     const highestId = Math.max.apply(Math, ccList.map(function (element) {
            return element.id;
        }));
        let newId = 1; // default in case the array is empty

        if (highestId > 0) {
            // generate a new ID based off of the highest existing element ID 
            newId = (highestId + 1);
        }
        return newId;
  };

  function createNewToDoItem_cc() {
    if (ccItem !== '') {
      const item = { id: generateId_cc(), text: ccItem };
      
      const tempArray = [...ccList, item];
      setCcList(tempArray);
    }
    setCcItem('');
  }

  function handleAdd_cc(e) {
    e.preventDefault();
    createNewToDoItem_cc();
  }

  const handleKeyPress_cc = e => {
    console.log("e.key....", e.key);
    if (e.key === 'Enter') {
      createNewToDoItem_cc();
    }else if (e.key === ' '){
      createNewToDoItem_cc();
    }else if (e.key === 'Tab'){
      createNewToDoItem_cc();
    }else if (e.key === 'Backspace' && ccItem===""){
      let tmp = ccList;
      tmp.splice(-1);
      setCcList([...tmp]);
    }

  };

  const deleteItem_cc = id => {
    setCcList(ccList.filter(item => item.id !== id));
  };

  const display_cc = ccList.map(item => (
    
    <div className="">
      {ValidateEmail(item.text) ? (
        <div className="user_list" style={{display: (idEdit != "cc"+item.id)  ? 'flex' : 'none'}}>
        <li key={item.id} className="valid">
          {item.name !==undefined && (<span><span>[</span>{item.name}<span>]</span></span>)} {item.text}
          </li>
          <span className="del_btn" onClick={() => {setIdEdit("cc"+item.id); setEditItem(item.text);}}><BsPencilFill/></span>

          <span className="del_btn" onClick={() => deleteItem_cc(item.id)}>X</span>
    </div>
      ) : (
        <div className="user_list unvalid" style={{display: (idEdit != "cc"+item.id)  ? 'flex' : 'none'}}>
        <li key={item.id}>
          {item.text}
          </li>
          <span className="del_btn" onClick={() => {setIdEdit("cc"+item.id); setEditItem(item.text);}}><BsPencilFill/></span>

          <span className="del_btn" onClick={() => deleteItem_cc(item.id)}>X</span>
    </div>
      )}

<input value={editItem}
          onChange={e => {
            setEditItem(e.currentTarget.value);
          }}
          onKeyDown={handleKeyPressEdit_cc} style={{width:"auto", display: (idEdit == "cc"+item.id)  ? 'block' : 'none'}}></input>

    </div>
    
  ));

  const [bccItem, setBccItem] = useState("");
  const [bccList, setBccList] = useState([]);

  const generateId_bcc = () => {
     const highestId = Math.max.apply(Math, bccList.map(function (element) {
            return element.id;
        }));
        let newId = 1; // default in case the array is empty

        if (highestId > 0) {
            // generate a new ID based off of the highest existing element ID 
            newId = (highestId + 1);
        }
        return newId;
  };

  function createNewToDoItem_bcc() {
    if (bccItem !== '') {
      const item = { id: generateId_bcc(), text: bccItem };
      const tempArray = [...bccList, item];
      setBccList(tempArray);
    }
    setBccItem('');
  }

  function handleAdd_bcc(e) {
    e.preventDefault();
    createNewToDoItem_bcc();
  }

  const handleKeyPress_bcc = e => {
    if (e.key === 'Enter') {
      createNewToDoItem_bcc();
  }else if (e.key === ' '){
    createNewToDoItem_bcc();
  }else if (e.key === 'Tab'){
    createNewToDoItem_bcc();
  }else if (e.key === 'Backspace' && bccItem===""){
    let tmp = bccList;
    tmp.splice(-1);
    setBccList([...tmp]);
  }
  };

  const deleteItem_bcc = id => {
    setBccList(bccList.filter(item => item.id !== id));
  };

  const display_bcc = bccList.map(item => (
    
    <div className="">
      {ValidateEmail(item.text) ? (
        <div className="user_list" style={{display: (idEdit != "bcc"+item.id)  ? 'flex' : 'none'}}>
        <li key={item.id} className="valid">
          {item.name !==undefined && (<span><span>[</span>{item.name}<span>]</span></span>)} {item.text}
          </li>
          <span className="del_btn" onClick={() => {setIdEdit("bcc"+item.id); setEditItem(item.text);}}><BsPencilFill/></span>

          <span className="del_btn" onClick={() => deleteItem_bcc(item.id)}>X</span>
    </div>
      ) : (
        <div className="user_list unvalid" style={{display: (idEdit != "bcc"+item.id)  ? 'flex' : 'none'}}>
        <li key={item.id}>
          {item.text}
          </li>
          <span className="del_btn" onClick={() => {setIdEdit("bcc"+item.id); setEditItem(item.text);}}><BsPencilFill/></span>

          <span className="del_btn" onClick={() => deleteItem_bcc(item.id)}>X</span>
    </div>
      )}
<input value={editItem}
          onChange={e => {
            setEditItem(e.currentTarget.value);
          }}
          onKeyDown={handleKeyPressEdit_bcc} style={{width:"auto", display: (idEdit == "bcc"+item.id)  ? 'block' : 'none'}}></input>


    </div>
    
  ));
  let files = [];
  // 등록 버튼 핸들러

  const handleDraftButton = () => {
    /*const formLargeData = new FormData();
   
    for (let i = 0; i<largeFiles.length;i++){
      formLargeData.append(`lfile[${i}]`, largeFiles[i]);
      formLargeData.append(`lfilename[${i}]`, largeFiles[i].name);
    }*/
    //alert(formLargeData);
    console.log(editorRef.current?.getInstance().getHTML());
    if (subject === "") {
      alert("제목을 입력해주세요.");
      return false;
    } else if (
      editorRef.current?.getInstance().getHTML() ===
      '<p><br className="ProseMirror-trailingBreak"></p>'
    ) {
      alert("내용을 입력해주세요.");
      return false;
    }
    var arrayLength = fileArray.length;
    var extractStr = editorRef.current?.getInstance().getMarkdown();
    for (var i = 0; i < arrayLength; i++) {
      let result = extractStr.match(fileArray[i]);
      console.log("result: " + result);
      if (result == null) {
        var filename = fileArray[i].substring(
          fileArray[i].lastIndexOf("/") + 1
        );

        axios
          .delete("https://gw.thegmmedical.com:5003/api/deletepic", {
            data: {
              // 서버에서 req.body.{} 로 확인할 수 있다.
              file_name: filename,
              board_name: boardName,
            },
          })
          .then(() => this.setState({ status: "Delete successful" }));
      }
      //Do something
    }

    const date_time = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
    const formData = new FormData();
   
    for (let i = 0; i<fileList.length;i++){
      console.log(fileList[i].name);
      formData.append(`file[${i}]`, fileList[i]);
      formData.append(`filename[${i}]`, fileList[i].name);
    }
    for (let i = 0; i<largeFiles.length;i++){
      formData.append(`lfile[${i}]`, largeFiles[i]);
      formData.append(`lfilename[${i}]`, largeFiles[i].name);
    }
    for (let i = 0; i<recList.length;i++){
      formData.append(`receive[${i}]`, recList[i].text);
    }
    for (let i = 0; i<ccList.length;i++){
      formData.append(`cc[${i}]`, ccList[i].text);
    }for (let i = 0; i<bccList.length;i++){
      formData.append(`bcc[${i}]`, bccList[i].text);
    }
    console.log(formData);
 
    if (pw!==""){
      formData.append(`content`, "");
      formData.append(`content_txt`, "");
    }else{
      formData.append(`content`, editorRef.current?.getInstance().getHTML());
      formData.append(`content_txt`, editorRef.current
      ?.getInstance()
      .getHTML()
      .replace(/<\/?[^>]+(>|$)/g, ""));
    }
    
    formData.append(`date_time`, date_time);
    formData.append(`subject`, subject);
    formData.append(`file`, mailFile);
    if (draftNo !==undefined){
      formData.append(`draft_no`, draftNo);
    }
    const fetch = async () => {
      await axios
        .post("https://gw.thegmmedical.com:5003/api/maildraft", formData, {
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        })
        .then(({ data }) => {
          console.log("what is this", data);
          alert("임시 저장했습니다.");
          //navigate("/list/all");
          //console.log("...." + data);
        }).catch(function (error) {
          if (error.response) {
            alert(error.response.data);
         
          }
          else if (error.request) {
           
            console.log(error.request);
          }
          else {
            console.log('Error', error.message);
          }
          console.log(error.config);
        });
    };
    fetch();
    
    /*const fetchLarge = async () => {
      await axios
        .post("https://gw.thegmmedical.com:5003/api/uploadlarge", formLargeData, {
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        })
        .then(({ data }) => {
          console.log("ddddddddddddddddddddddddddddd");

          //console.log("...." + data);
        });
    };
    fetchLarge();

*/



  };
  const receiveFunc = (receivers) =>{
    let list = JSON.parse(receivers);

    var tempArray =[];
        for (let i=0;i<list.length;i++){
          let email = list[i];
          const name = "";
  
          const item = { id: generateId_rec(), name : name, text: email };
          //tempArray = [...recList, item];
          tempArray.push(item);
        }
          setRecList(tempArray);
      }
  const handleRegisterButton = () => {
    /*const formLargeData = new FormData();
   
    for (let i = 0; i<largeFiles.length;i++){
      formLargeData.append(`lfile[${i}]`, largeFiles[i]);
      formLargeData.append(`lfilename[${i}]`, largeFiles[i].name);
    }*/
    //alert(formLargeData);


    console.log(editorRef.current?.getInstance().getHTML());
    if (subject === "") {
      alert("제목을 입력해주세요.");
      return false;
    } else if (
      editorRef.current?.getInstance().getHTML() ===
      '<p><br className="ProseMirror-trailingBreak"></p>'
    ) {
      alert("내용을 입력해주세요.");
      return false;
    }else if (recList.length===0){
      alert("수신자를 입력해주세요.");
      return;
    }
    var temp = fileList;
    delZipFiles(temp);
    var arrayLength = fileArray.length;
    var extractStr = editorRef.current?.getInstance().getMarkdown();
    for (var i = 0; i < arrayLength; i++) {
      let result = extractStr.match(fileArray[i]);
      console.log("result: " + result);
      if (result == null) {
        var filename = fileArray[i].substring(
          fileArray[i].lastIndexOf("/") + 1
        );

        axios
          .delete("https://gw.thegmmedical.com:5003/api/deletepic", {
            data: {
              // 서버에서 req.body.{} 로 확인할 수 있다.
              file_name: filename,
              board_name: boardName,
            },
          })
          .then(() => this.setState({ status: "Delete successful" }));
      }
      //Do something
    }

    const date_time = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
    const formData = new FormData();
   
    console.log("filelist.................", fileList);
    for (let i = 0; i<fileList.length;i++){
      console.log(fileList[i].name);
      formData.append(`file[${i}]`, fileList[i]);
      formData.append(`filename[${i}]`, fileList[i].name);
    }
    for (let i = 0; i<largeFiles.length;i++){
      formData.append(`lfile[${i}]`, largeFiles[i]);
      formData.append(`lfilename[${i}]`, largeFiles[i].name);
    }
    for (let i = 0; i<recList.length;i++){
      if (!ValidateEmail(recList[i].text)){
        alert("올바른 메일 형식을 입력해주세요");
        return false;
      }
      formData.append(`receive[${i}]`, JSON.stringify({name : recList[i].name, address: recList[i].text}));

      //formData.append(`receive[${i}]`, recList[i].text);


    }
    for (let i = 0; i<ccList.length;i++){
      if (!ValidateEmail(ccList[i].text)){
        alert("올바른 메일 형식을 입력해주세요");
        return false;
      }
      formData.append(`cc[${i}]`, ccList[i].text);
    }for (let i = 0; i<bccList.length;i++){
      if (!bccList(ccList[i].text)){
        alert("올바른 메일 형식을 입력해주세요");
        return false;
      }
      formData.append(`bcc[${i}]`, bccList[i].text);
    }
    console.log(formData);
 
    if (pw!==""){
      formData.append(`content`, "");
      formData.append(`content_txt`, "");
      let pwfilename = subject + ".html"
      var f = new File([html], pwfilename);
    
      formData.append(`file`, f);
    }else{
      formData.append(`content`, editorRef.current?.getInstance().getHTML());
      formData.append(`content_txt`, editorRef.current
      ?.getInstance()
      .getHTML()
      .replace(/<\/?[^>]+(>|$)/g, ""));
    }
    
    formData.append(`date_time`, date_time);
    formData.append(`subject`, subject);
 
   formData.append(`send_email`, sendEmail);
   formData.append(`from_name`, fromName);


    const fetch = async () => {
      await axios
        .post("https://gw.thegmmedical.com:5003/api/mailinsert", formData, {
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        })
        .then(({ data }) => {
          console.log("what is this", data);
          if (data.responseCode!==554){
          setDateTime(date_time)
          setDone(true);
          }else{
            alert(data.response)
          }
          //navigate("/list/all");
          //console.log("...." + data);
        }).catch(function (error) {
          if (error.response) {
            alert(error.response.data);
         
          }
          else if (error.request) {
           
            console.log(error.request);
          }
          else {
            console.log('Error', error.message);
          }
          console.log(error.config);
        });
    };
    fetch();
    
    /*const fetchLarge = async () => {
      await axios
        .post("https://gw.thegmmedical.com:5003/api/uploadlarge", formLargeData, {
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        })
        .then(({ data }) => {
          console.log("ddddddddddddddddddddddddddddd");

          //console.log("...." + data);
        });
    };
    fetchLarge();

*/



  };

  function testest(){
    /*const formLargeData = new FormData();
   
    for (let i = 0; i<largeFiles.length;i++){
      formLargeData.append(`lfile[${i}]`, largeFiles[i]);
      formLargeData.append(`lfilename[${i}]`, largeFiles[i].name);
    }
    const fetchLarge = async () => {
      await axios
        .post("https://gw.thegmmedical.com:5003/api/uploadlarge", formLargeData, {
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        })
        .then(({ data }) => {
          console.log("ddddddddddddddddddddddddddddd");

          //console.log("...." + data);
        });
    };
    fetchLarge();*/
    
  }

  function getExtension(filename){
    var parts = filename.split('.');
    return parts[parts.length-1];
  }
  var JSZip = require('jszip')
 
  async function getZipFilesContent (data) {
    const zipContent = []
    const promises = []
    const zip = (await JSZip.loadAsync(data, {
      decodeFileName: function (bytes) {
        return iconv.decode(bytes, 'Shift_JIS');
      }
    }))
    zip.forEach(async (relativePath, file) => {
      const promise = file.async('string')
      promises.push(promise)
      zipContent.push({
        file: relativePath,
        content: await promise
      })
    })
  
    await Promise.all(promises)
    return zipContent
  }
  useEffect(()=>{
    const data = fileList;
    for (let i=0;i<data.length;i++){      

      var extt = getExtension(data[i].name);    
    
      if  (extt ==="zip"){

        var results = getZipFilesContent(data[i])
        results.then((appData) => {
          console.log(appData);
          //oldFiles.push(event.target.files[i]);
          //setFileList(oldFiles);
          for (let j=0;j<appData.length;j++){

            console.log("appdata[i]......", appData[j]);
            data.splice(i, 1);
            console.log("data",data);
            setFileList(data);
            //setFileList(data);
            return data;
            
          }

        });
        
        };
        
      }
  }, [])

  var values = ['3gp' , '3g2' , '7z'  ,  'Aac'  ,  'Avi'  ,  'bmp'  ,  'csv'  ,
        'doc'  ,  'docm'  ,  'docx'  ,  'Eot'  ,  'Epub'  ,  'gif'  ,  'gz'  , 
        'ico'  ,  'Ics'  ,  'jpg'  ,  'jpeg'  ,  'json'  ,  'mid'  ,  'midi'  ,
        'Mp3'  ,  'mp4'  ,  'Mpeg'  ,  'Oga'  ,  'ogv'  ,  'ogx'  ,  'Otf'  ,
        'pdf'  ,  'png'  ,  'ppsm'  ,  'ppsx'  ,  'ppt'  ,  'pptm'  ,  'pptx'  ,
        'pub'  ,  'rar'  ,  'rpmsg'  ,  'rtf'  ,  'svg'  ,  'tif'  ,  'tiff' ,
        'Ttf'  ,  'txt'  ,  'Vsd'  ,  'wav'  ,  'weba'  ,  'Webm'  ,  'webp'  ,
        'wma'  ,  'Wmv'  ,  'woff'  ,  'woff2'  ,  'xls'  ,  'xlsb'  ,  'xlsm'  ,
        'xlsx'  ,  'xml'  ,  'crt'  ,  'key'];
  const delZipFiles = (data) =>{
    for (let i=0;i<data.length;i++){      

      var extt = getExtension(data[i].name);    
    
      if  (extt ==="zip"){

        var results = getZipFilesContent(data[i])
        results.then((appData) => {
          console.log(appData);
          //oldFiles.push(event.target.files[i]);
          //setFileList(oldFiles);
          for (let j=0;j<appData.length;j++){

            var exts = getExtension(appData[j].file);
            console.log("appdata[i]......", appData[j], exts);

            if (!values.includes(exts)){
            alert("허용되지 않은 파일 형식이 있습니다.")
            data.splice(i, 1);
            console.log("data",data);
            setFileList(data);

            const event = new Event("build");
            fileChangedHandler(event);
            return data;

            }
            
            
          }

        });
        
        };
        
      }
  }
  const fileChangedHandler = (event) => {
    //alert("hi");
    //let file_size = event.target.files[0].size;
    var oldFiles = fileList;
    console.log("event.....", event);
    console.log("bef oldfiels..." , oldFiles);


    var newFiles = [];
    const LargeFs = [];
    var file__size=0;
    var fs = 0;
    const promises = [];
    var zips = [];

    if (event.target!==null){
    for(let i = 0; i < event.target.files.length; i++){
      file__size+=event.target.files[i].size;
      
      //filesize+=event.target.files[i].size;
      console.log(event.target.files[i]);
      if ((fileSize+file__size)>20971520  , file__size > 20971520){
        //LargeFs.push(event.target.files[i]);
        if (i ===event.target.files.length-1){
          alert("20,971,520 Bytes 이상의 파일은 첨부하실 수 없습니다. 본문에 대용량 첨부 기능을 사용하시기 바랍니다.");
        }
       
       
      }else{
        var ext = getExtension(event.target.files[i].name);
        var flag = false;
        if (ext === 'gz'){
          var parts = event.target.files[i].name.split('.');
            if (parts[parts.length-2]!=="tar"){
              
            }else{
              zips.push(event.target.files[i]);
            }

        }else if (ext === "zip" || ext === "tar"){
          
            
          zips.push(event.target.files[i]);

          
        }
        
        
        
        switch (ext){
          case 'zip':
          case '3g2':
          case '7z':
          case 'Aac':
          case 'Avi':
          case 'bmp':
          case 'csv':
          case 'doc':
          case 'docm':
          case 'docx':
          case 'Eot':
          case 'Epub':
          case 'gif':
          case 'gz':
          case 'ico':
          case 'Ics':
          case 'jpg':
          case 'jpeg':
          case 'json':   
          case 'mid':          
          case 'midi':          
          case 'Mp3':          
          case 'mp4':          
          case 'Mpeg':          
          case 'Oga':          
          case 'ogv':          
          case 'ogx':          
          case 'Otf':          
          case 'pdf':   
          case 'png':          
          case 'ppsm':          
          case 'ppsx':          
          case 'ppt':          
          case 'pptm':          
          case 'pptx':          
          case 'pub':          
          case 'rar':          
          case 'rpmsg':          
          case 'rtf':          
          case 'svg':          
          case 'tif':          
          case 'tiff':          
          case 'Ttf':          
          case 'txt':          
          case 'Vsd':          
          case 'wav':          
          case 'weba':          
          case 'Webm':          
          case 'webp':          
          case 'wma':          
          case 'Wmv':          
          case 'woff':          
          case 'woff2':          
          case 'xls':       
          case 'crt':    
          case 'key':     
          case 'xlsb':          
          case 'xlsm':          
          case 'xlsx':          
          case 'xml':          
            
            fs+=event.target.files[i].size;
            console.log("new file...", event.target.files[i]);
            oldFiles.push(event.target.files[i]);
            
            break;
          
          default : alert("허용되지 않는 파일 형식입니다."); 
        }
        
        
        
      }
    }
  }
    var removed  = oldFiles;

    const data = delZipFiles(oldFiles);
    
    
      // do something with data
      setFileSize(fileSize+fs);
      var newArray = fileList.concat(newFiles);
      setLargeFiles(LargeFs);
      console.log("after oldfiels..." , oldFiles);
      setFileList(oldFiles);
    
   
  
  
  };





  function popupwindow(url, title, w, h) {
    var left = (window.screen.width/2)-(w/2);
    var top = (window.screen.height/2)-(h/2);
    return window.open(url, title, 'toolbar=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width='+w+', height='+h+', top='+top+', left='+left);
  } 

  const downloadEmployeeData = () => {
    fetch('https://gw.thegmmedical.com:5003/api/download')
            .then(response => {
                response.blob().then(blob => {
                    let url = window.URL.createObjectURL(blob);
                    let a = document.createElement('a');
                    a.href = url;
                    a.download = '이호MH호텔_셀렉 A컷.zip';
                    a.click();
                });
                //window.location.href = response.url;
        });
  }
 
  const [pw, setPw] = useState("");
  const [mailFile, setMailFile] = useState();
  const [isVerified, setIsVerified] = useState();


function download2(){

  const answer = document.getElementById("password").value;

  if (answer === "940607") { 
  setIsVerified(true);
  } else {
  alert("Sorry, that's not it");
  }

 
}

  function mailPw() {
    let text;
    let person = prompt("메일 암호를 입력해주세요:", "");
    if (person == null  , person == "") {
      text = "취소하였습니다.";
    } else {
      text = "암호 : " + person;
      setPw(person);
    }
   
    //var pageHTML = window.document.getElementById('mail').innerHTML;
    var pageHTML = editorRef.current?.getInstance().getHTML();
  var script = "<script type = 'text/javascript'> document.getElementById('button').onclick = function() {var pw=document.getElementById('password').value;  if (pw == '"+person +"'){ document.getElementById('content').innerHTML = '"+pageHTML+"'; document.getElementById('pwinput').innerHTML = '';} }</script>";
    var html = <div className="wrapper" id="divToPDF" style={{width:"100%", height:"100%", display:"flex", alignItems:"center", justifyContent:"center"}}>
    
    <div className="contents_wrap">
    <div id="content">
       
    </div>
    
        <div id="pwinput" style={{textAlign:"center", paddingBottom:"35px"}}>  
        <div style={{float:"left", marginLeft:"99px"}}>
           <input maxlength="15" name="submitBirth" id="password" 
           style={{height:"38px", border:"1px solid #d9d9d9", color:"#1a1a1a",     width:"249px", paddingLeft:"15px"}}
           type="password" placeholder=" 예 : 890101 "></input>
           </div>                 
           <div onClick={()=>{
            const answer = document.getElementById("password").value;

            if (answer === "940607") { 
            setIsVerified(true);
            } else {
            alert("비밀번호가 틀렸습니다.");
            }
          
          
          }} style={{float:"left"}}><button type="button" id="button"
           style={{border:"0", outline:"0", backgroundColor:"white" }}>
               <img src="https://www.iquest.co.kr/images/mailform/btn_pay_submit.png" alt="absmiddle"/></button>
               </div>               
           </div>
    
   
     
    </div>
    </div>;
    html = renderToString(html);
    html = html + script;
    setHtml(html);
    
  }
  const changeInputValue = event => {
    setInputValue(event.target.value)
    setIsHaveInputValue(true)
    
  }

  const clickDropDownItem = clickedItem => {
    setInputValue(clickedItem);setRecItem(clickedItem);
    const email = clickedItem.substring(clickedItem.indexOf("<")+1, clickedItem.indexOf(">"));
    const name = clickedItem.substring(clickedItem.indexOf(0), clickedItem.indexOf("<"));
    if (clickedItem !== '') {
      const item = { id: generateId_rec(), name: name ,text: email };
      const tempArray = [...recList, item];
      setRecList(tempArray);
    }
    setRecItem('');
    setIsHaveInputValue(false)
    console.log(clickedItem);
    
  }
  const clickDropDownItem_cc = clickedItem => {
    setInputValue(clickedItem);setCcItem(clickedItem);
    const email = clickedItem.substring(clickedItem.indexOf("<")+1, clickedItem.indexOf(">"));
    const name = clickedItem.substring(clickedItem.indexOf(0), clickedItem.indexOf("<"));
    if (clickedItem !== '') {
      const item = { id: generateId_cc(), name: name ,text: email };
      const tempArray = [...ccList, item];
      setCcList(tempArray);
    }
    setCcItem('');
    setIsHaveInputValue(false)
    console.log(clickedItem);
    
  }

  const clickDropDownItem_bcc = clickedItem => {
    setInputValue(clickedItem);setBccItem(clickedItem);
    const email = clickedItem.substring(clickedItem.indexOf("<")+1, clickedItem.indexOf(">"));
    const name = clickedItem.substring(clickedItem.indexOf(0), clickedItem.indexOf("<"));
    if (clickedItem !== '') {
      const item = { id: generateId_bcc(), name: name ,text: email };
      const tempArray = [...bccList, item];
      setBccList(tempArray);
    }
    setBccItem('');
    setIsHaveInputValue(false)
    console.log(clickedItem);
    
  }

  const handleDropDownKey = event => {

  
    //input에 값이 있을때만 작동
    if (isHaveInputValue) {
      if (
        event.key === 'ArrowDown' &&
        dropDownList.length - 1 > dropDownItemIndex
      ) {
        setDropDownItemIndex(dropDownItemIndex + 1)
      }

      if (event.key === 'ArrowUp' && dropDownItemIndex >= 0)
        setDropDownItemIndex(dropDownItemIndex - 1)

        if (event.key === 'Enter'){
          createNewToDoItem_rec();
        }
      if (event.key === 'Enter' && dropDownItemIndex >= 0) {
        clickDropDownItem(dropDownList[dropDownItemIndex])
        setDropDownItemIndex(-1)
        createNewToDoItem_rec();
      }

      if (event.key === ' '){
        createNewToDoItem_rec();
      }
      if (event.key === 'Tab'){
        createNewToDoItem_rec();
      }
    }
  }
  const handleDropDownKey_cc = event => {
    //input에 값이 있을때만 작동
    if (isHaveInputValue) {
      if (
        event.key === 'ArrowDown' &&
        dropDownList.length - 1 > dropDownItemIndex
      ) {
        setDropDownItemIndex(dropDownItemIndex + 1)
      }

      if (event.key === 'ArrowUp' && dropDownItemIndex >= 0)
        setDropDownItemIndex(dropDownItemIndex - 1)

        if (event.key === 'Enter'){
          createNewToDoItem_cc();
        }
      if (event.key === 'Enter' && dropDownItemIndex >= 0) {
        clickDropDownItem_cc(dropDownList[dropDownItemIndex])
        setDropDownItemIndex(-1)
        createNewToDoItem_cc();
      }

      if (event.key === ' '){
        createNewToDoItem_cc();
      }
      if (event.key === 'Tab'){
        createNewToDoItem_cc();
      }
    }
  }

  const handleDropDownKey_bcc = event => {
    //input에 값이 있을때만 작동
    if (isHaveInputValue) {
      if (
        event.key === 'ArrowDown' &&
        dropDownList.length - 1 > dropDownItemIndex
      ) {
        setDropDownItemIndex(dropDownItemIndex + 1)
      }

      if (event.key === 'ArrowUp' && dropDownItemIndex >= 0)
        setDropDownItemIndex(dropDownItemIndex - 1)

        if (event.key === 'Enter'){
          createNewToDoItem_bcc();
        }
      if (event.key === 'Enter' && dropDownItemIndex >= 0) {
        clickDropDownItem_bcc(dropDownList[dropDownItemIndex])
        setDropDownItemIndex(-1)
        createNewToDoItem_bcc();
      }

      if (event.key === ' '){
        createNewToDoItem_bcc();
      }
      if (event.key === 'Tab'){
        createNewToDoItem_bcc();
      }
    }
  }

  const showDropDownList = () => {
    if (recItem === '') {
      setIsHaveInputValue(false)
      setDropDownList([])
    } else {
      const choosenTextList = peopleArray.filter(textItem =>
        textItem.includes(recItem)
      )

      console.log("choosenTextList..........", choosenTextList);
      setDropDownList(choosenTextList)
    }
  }

  const showDropDownList_cc = () => {
    if (ccItem === '') {
      setIsHaveInputValue(false)
      setDropDownList([])
    } else {
      const choosenTextList = peopleArray.filter(textItem =>
        textItem.includes(ccItem)
      )
      setDropDownList(choosenTextList)
    }
  }

  const showDropDownList_bcc = () => {
    if (bccItem === '') {
      setIsHaveInputValue(false)
      setDropDownList([])
    } else {
      const choosenTextList = peopleArray.filter(textItem =>
        textItem.includes(bccItem)
      )
      setDropDownList(choosenTextList)
    }
  }

  useEffect(showDropDownList, [recItem])
  useEffect(showDropDownList_cc, [ccItem])
  useEffect(showDropDownList_bcc, [bccItem])
  /*useEffect(() => {
    console.log("file list........", fileList);
    var temp = fileList;
    delZipFiles(temp);
  },[fileList]);
  */
 useEffect(() => {
    console.log("is it working or not");

    const response = document.getElementById('response');
    var link_array=[];

    function addDays(days) {
      var date = new Date();
      date.setDate(date.getDate() + days);
      return date;
    }
    

    window.addEventListener('message', (event) => {
      if (event.data["msg"] !== undefined && cnt === 0){
        
        console.log(document.getElementsByClassName('large_file'));
        //document.getElementsByClassName('large_file')[0]="";
        //if ()
        var files = event.data["msg"];
        console.log("files....length     ", files.length);
        for (let i= 0;i<files.length;i++){
          var encoded = encodeURIComponent(files[i].filepath);
          var string = "<div className='large_file'>대용량첨부파일 : ";
          string+='<a href = "https://gw.thegmmedical.com:5003/download/'+encoded+'" download rel="" target="">'+files[i].name+'</a>'
          string+=" ~ "+moment(addDays(7)).format('YYYY-MM-DD')+"</div>";
          var content = editorRef.current?.getInstance().getHTML();
        console.log("content............",content);
        content.replaceAll("대용량첨부파일", "");
        
        string+=content;
        editorRef.current?.getInstance().setHTML(string);
        }
        
        
        
        let tmp = cnt;
        tmp++;
        setCnt(tmp);
        event.data["msg"]=[];
  
      }
      if (event.data["toList"] !== undefined){
        console.log(event.data["toList"]);
        var tempArray =[];
        for (let i=0;i<event.data["toList"].length;i++){
          let itemText = event.data["toList"][i].name;
          let lastIndex =itemText.length;
          const name = itemText.substring(itemText.indexOf("[")+1, itemText.indexOf("]"));
          const email = itemText.substring(itemText.indexOf(" ")+1, lastIndex);
  
          const item = { id: generateId_rec(), name : name, text: email };
          //tempArray = [...recList, item];
          tempArray.push(item);
        }
          setRecList(tempArray);
        //setRecList(event.data["toList"]);
      }
      if (event.data["ccList"] !== undefined){
        console.log(event.data["ccList"]);
        var ccArray =[];
        for (let i=0;i<event.data["ccList"].length;i++){
          let itemText = event.data["ccList"][i].name;
          let lastIndex =itemText.length;
          const name = itemText.substring(itemText.indexOf("[")+1, itemText.indexOf("]"));
          const email = itemText.substring(itemText.indexOf(" ")+1, lastIndex);
          const item = { id: generateId_cc(), name : name, text: email };
          //tempArray = [...recList, item];
          ccArray.push(item);
        
          }
          setCcList(ccArray);
        //setRecList(event.data["toList"]);
      }
  
      if (event.data["bccList"] !== undefined){
        console.log(event.data["bccList"]);
        var bccArray =[];
        for (let i=0;i<event.data["bccList"].length;i++){
  
          let itemText = event.data["ccList"][i].name;
          let lastIndex =itemText.length;
          const name = itemText.substring(itemText.indexOf("[")+1, itemText.indexOf("]"));
          const email = itemText.substring(itemText.indexOf(" ")+1, lastIndex);
  
          const item = { id: generateId_bcc(), name : name, text: email  };
          //tempArray = [...recList, item];
          bccArray.push(item);
        
          }
          setBccList(bccArray);
        //setRecList(event.data["toList"]);
      }
    },false);

    
    async function fetchboxes(){
      await fetch("https://gw.thegmmedical.com:5003/api/getpeople", {
        method: "get", // 통신방법
        headers: {
          "content-type": "application/json",
        },
        credentials: "include",
      })
      .then((res) => res.json())
      .then((json) => {
            var tempArray = [];
            for (let i =0;i<json.length;i++){
        
              tempArray.push(json[i].name +"<"+ json[i].email+">");
        
            }
            axios.get('https://gw.thegmmedical.com:5003/api/get_addrs')
            .then(function (res){

              for (let i =0;i<res.data.length;i++){
                
                console.log("addrs........", res.data);
                const subArr = tempArray.some(str => str.includes("<"+ res.data[i].email+">"));
                if (subArr === false){
                  if (res.data[i].name == null || res.data[i].name == undefined){
                    tempArray.push("<"+ res.data[i].email+">");
                  }else{
                    tempArray.push(res.data[i].name +"<"+ res.data[i].email+">");
                  }    
                }
                         
              }
              setPeopleArray(tempArray);
              
              
            //json.shift();
              

            })
            .catch(function (error){
              console.log(error);
            })
            .then(function (){
              //always executed
            });
            
        //json.shift();
        
        //console.log(boxes.boxes);
        //console.log(boxes.boxes);
      })
      .catch(() => {});
    };
    fetchboxes();


    
    
}, []);
const addBookmarkPage = () => {
  var result = window.confirm("메뉴 바로가기에 추가하시겠습니까?");
  if(result){

    const data ={
      name: '',
      num : '',
      link : window.location.pathname,
      bookmark : "메일 쓰기"
    }
    
    console.log(window.location.pathname)
    console.log(data);
    axios.post('https://gw.thegmmedical.com:5003/api/add_bookmark_page', data)
    .then(function (response){
      console.log(response);
      alert("메뉴 바로가기에 추가되었습니다.");

    })
    .catch(function (error){
      console.log(error);
    })
    .then(function (){
      //always executed
    });
  }
};
const [uploadedInfo, setUploadedInfo] = useState(null);
  
// ...생략

const setFileInfo = (file) => {
  const { name, type } = file;
  const isImage = type.includes('image');
  const size = (file.size / (1024 * 1024)).toFixed(2) + 'mb';

  if (!isImage) {
    setUploadedInfo({ name, size, type });
    return;
  }
  const reader = new FileReader();
  reader.onload = () => {
    setUploadedInfo({ name, size, type, imageUrl: String(reader.result) });
  };
  reader.readAsDataURL(file);
};

const handleDrop = (event) => {
  event.preventDefault();
  setActive(false);

  const files = event.dataTransfer.files;
  var oldFiles = fileList;
    console.log("event.....", files);
    console.log("bef oldfiels..." , oldFiles);


    var newFiles = [];
    const LargeFs = [];
    var file__size=0;
    var fs = 0;
    const promises = [];
    var zips = [];

    for(let i = 0; i < files.length; i++){
      file__size+=files[i].size;
      
      if ((fileSize+file__size)>20971520  , file__size > 20971520){
        //LargeFs.push(event.target.files[i]);
        if (i ===files.length-1){
          alert("20,971,520 Bytes 이상의 파일은 첨부하실 수 없습니다. 본문에 대용량 첨부 기능을 사용하시기 바랍니다.");
        }
       
       
      }else{
        var ext = getExtension(files[i].name);
        var flag = false;
        if (ext === 'gz'){
          var parts = files[i].name.split('.');
            if (parts[parts.length-2]!=="tar"){
              
            }else{
              zips.push(files[i]);
            }

        }else if (ext === "zip" || ext === "tar"){
          
            
          zips.push(files[i]);

          
        }
        
        
        
        switch (ext){
          case 'zip':
          case '3g2':
          case '7z':
          case 'Aac':
          case 'Avi':
          case 'bmp':
          case 'csv':
          case 'doc':
          case 'docm':
          case 'docx':
          case 'Eot':
          case 'Epub':
          case 'gif':
          case 'gz':
          case 'ico':
          case 'Ics':
          case 'jpg':
          case 'jpeg':
          case 'json':   
          case 'mid':          
          case 'midi':          
          case 'Mp3':          
          case 'mp4':          
          case 'Mpeg':          
          case 'Oga':          
          case 'ogv':          
          case 'ogx':          
          case 'Otf':          
          case 'pdf':   
          case 'png':          
          case 'ppsm':          
          case 'ppsx':          
          case 'ppt':          
          case 'pptm':          
          case 'pptx':          
          case 'pub':          
          case 'rar':          
          case 'rpmsg':          
          case 'rtf':          
          case 'svg':          
          case 'tif':          
          case 'tiff':          
          case 'Ttf':          
          case 'txt':          
          case 'Vsd':          
          case 'wav':          
          case 'weba':          
          case 'Webm':          
          case 'webp':          
          case 'wma':          
          case 'Wmv':          
          case 'woff':          
          case 'woff2':          
          case 'xls':       
          case 'crt':    
          case 'key':     
          case 'xlsb':          
          case 'xlsm':          
          case 'xlsx':          
          case 'xml':          
            
            fs+=files[i].size;
            console.log("new file...", files[i]);
            oldFiles.push(files[i]);
            
            break;
          
          default : alert("허용되지 않는 파일 형식입니다."); 
        }
        
        
        
      }
    }
  
    var removed  = oldFiles;

    const data = delZipFiles(oldFiles);
    
    
      // do something with data
      setFileSize(fileSize+fs);
      var newArray = fileList.concat(newFiles);
      setLargeFiles(LargeFs);
      console.log("after oldfiels..." , oldFiles);
      setFileList(oldFiles);
    
   
   // 코드 추가
};

const handleUpload = ({ target }) => {
  const file = target.files[0];
  console.log("file...", file);
  setFileInfo(file);  // 코드 추가
};

const handleDragOver = (event) => {
  event.preventDefault();  // 필수 1
};


function createRecList(value){
  setRecItem(''); 
  console.log("value.....", value);
  if (value !== '') {
    const highestId = Math.max.apply(Math, recList.map(function (element) {
      return element.id;
  }));
  let newId = 1; // default in case the array is empty

  if (highestId > 0) {
      // generate a new ID based off of the highest existing element ID 
      newId = (highestId + 1);
  }
  
    const item = { id: newId, text: value };
    const tempArray = [...recList, item];
    setRecList(tempArray);console.log(item);
    setRecItem("");
  }
  
}
/*window.addEventListener('click', function(e){   
  let div = document.querySelector('.dropdown_box');
  let item = recItem;
  if (div !==undefined && div !==null){
    
    
    if (div.contains(e.target)){
    // Clicked in box
    alert("A");
    } else{
      console.log("recItem", item);
      createRecList(item);
      
      div.style.display = 'none';
      //alert("B");
      //createNewToDoItem_rec();
      //
      
    }
  }else{
    if (recItem!==''){
      console.log("recItem", item);
      createRecList(item);
      
      //alert("c");
    }
    
  }
  
});
*/

const ref = useClickAway(() => {
  if (recItem!==''){
    createNewToDoItem_rec();
    let div = document.querySelector('.dropdown_box');
    if (div !==undefined && div !==null){
      div.style.display = 'none';
    }
  }
  
  console.log("clicked");
  //setIsOpen(false);
 
  
});

const ccRef = useClickAway(() => {
  if (ccItem!==''){
    createNewToDoItem_cc();
    let div = document.querySelector('.dropdown_box');
  if (div !==undefined && div !==null){
    div.style.display = 'none';
  }
  }
  console.log("clicked cc");
  //setIsOpen(false);
  
  
});

const bccRef = useClickAway(() => {
  if (bccItem!==''){
    createNewToDoItem_bcc();
    let div = document.querySelector('.dropdown_box');
  if (div !==undefined && div !==null){
    div.style.display = 'none';
  }
  }
  console.log("clicked bcc");
  //setIsOpen(false);
  
  
});

  return (


    <div className="wrapper" id="mail">

<div className="sidebar-icon" style={{margin:"0 auto"}} onClick={()=>{if (sidebar === false){document.getElementsByTagName("main")[0].style.overflow="hidden"}else{document.getElementsByTagName("main")[0].style.overflow="auto"} setSidebar(!sidebar);}}>
        <GiHamburgerMenu/>
      </div>
      {sidebar && (
        <div className="side-mobile-menu">
        <MailNav boxes={boxes}/>
        </div>
      )}


 {show ? (
    <MailDraftsList onChange={(value) => 
      {setSubject(value.subject);
      editorRef.current?.getInstance().setHTML(value.content);
      receiveFunc(value.to_addr);
      setDraftNo(value.draft_no)}} closePopup={() => toggleShow()}/>
    ) : null}
<div className="content-wrap">
  <div className="outer_wrapper" style={{justifyContent:"flex-start", width:"100%"}}>
  <div className="side-menu">

  
  <MailNav boxes={boxes}/>        {/*<form>*/}
</div>
{done && (
  <div className="mail_done">
<div style={{display:"flex", alignItems:"center"}}><FaPaperPlane style={{marginRight:"10px"}}/><h2>메일을 전송했습니다.</h2></div>
<table width="100%" border="0" cellspacing="0" cellpadding="0">
<tbody>
<tr height="40">
    <td>메일 제목 : </td><td class="table_list" align="center">{subject}</td>
</tr>
<tr height="40">

    <td>받는사람 : </td><td class="table_list" align="center">{recList.map((ele,idx)=>(
      <li key={ele.id} className="valid">
      {(ele.name !==undefined && ele.name!=="") && (<span><span>[</span>{ele.name}<span>]</span></span>)} {ele.text}
      </li>
    ))}</td>
    
</tr>
<tr height="40">

    <td>발신일자 : </td><td class="table_list" align="center">{dateTime}</td>
    
</tr>
</tbody></table>

<div className="btns" style={{display:"flex",alignItems:"center", justifyContent:"center", marginTop:"80px"}}>

            

<div className="btn" style={{display:"flex", alignItem:"center",justifyContent:"center",marginRight:"10px", padding:"15px 0"}}>
  <Link to="/list/INBOX">확인</Link>
</div>
<div className="btn" style={{display:"flex", alignItem:"center", justifyContent:"center",padding:"15px 0"}}>
  <a href="/write">메일 쓰기</a>
</div>


</div>

</div>
)}
  
{!done && (
  <div className="mail_content">
  <div style={{display:"flex", width:"100%", margin:"0 auto", alignItems:"center"}}>
  <h3>메일 쓰기</h3>



  <div className="btn" style={{display:"flex", alignItem:"center", 
  height:"15px",padding:"5px", marginRight:"7px"}}  onClick={()=>addBookmarkPage()}><BsPin/></div>
  </div>


  <div className="btns" style={{display:"flex",justifyContent:"space-between", width:"100%", alignItems:"start",margin:"0 auto", marginBottom:"20px"}}>
  

<div className="left">
      <button className="btn" onClick={handleRegisterButton}>등록</button>
      <button className="btn" onClick={handleDraftButton}>임시저장</button>
      <button className="btn" onClick={()=>mailPw()}>암호메일</button>
      {pw && (
        <div style={{marginTop:"10px", display:"flex", flexDirection:"column"}}>
          암호 메일 비밀번호 : {pw}
          <div style={{marginTop:"10px"}}> <button onClick={()=>mailPw()}>변경</button>
          <button onClick={()=>{
            var result = window.confirm("암호메일을 해제하시겠습니까?");
            if(result){
              setPw("");
            }
          }}>암호메일 해제</button> </div>
        </div>
      )}
      {/*</form>*/}
     
      </div>
      <div className="right">
                <button onClick={() => {setShow(!show);}}>임시저장글</button> 

            </div>
</div>
      
      <table className="write_table">

        {/*<button onClick={()=>downloadEmployeeData()}>test...</button>*/}
        <tr>
  <td class="table_title2" nowrap="">
    보내는사람
  </td>
    <td class="table_list2_left">
    <div className="input-group" style={{width:"100%"}}>

          <select onChange={(e)=>{setSendEmail(addresses[e.currentTarget.value].email);
          setFromName(addresses[e.currentTarget.value].name);
          }}>

          {addresses.map((element,index)=>(
            <option value={index}>[{element.name}] {element.email}</option>
          ))}

          </select>

    </div>
    </td>
  </tr>
    <tr style={{height:"auto", minHeight:"50px"}}>  
    <td class="table_title2" nowrap="">
    <FaAddressBook id='' style={{cursor:'pointer'}} onClick={() => popupwindow("/contacts", "contacts", 800, 800)} alt=''>
      
      </FaAddressBook>
        받는사람
      
    </td>
    <td class="table_list2_left">
        <div className="input-group">
        <ul className="rec_list" style={{flexWrap:"wrap"}}>{display_rec}



        <li style={{position:"relative"}} isHaveInputValue={isHaveInputValue} ref={ref}><input
          type='text'
          value={recItem}
          onChange={e => {
            setRecItem(e.currentTarget.value);
          }}
          onKeyUp={handleDropDownKey}
          onKeyDown={handleKeyPress_rec}
          //onBlur={handleBlur_rec} 
          
        />
        
        {(recItem && dropDownList.length > 0)&& (
        <DropDownBox className="dropdown_box">
      
          {dropDownList.map((dropDownItem, dropDownIndex) => {
            return (
              <DropDownItem
                key={dropDownIndex}
                onClick={() => clickDropDownItem(dropDownItem)}
                onMouseOver={() => setDropDownItemIndex(dropDownIndex)}
                className={
                  dropDownItemIndex === dropDownIndex ? 'selected' : ''
                }
              >
                {dropDownItem}
              </DropDownItem>
            )
          })}
        </DropDownBox>
      )}
      </li>
        {/*<li><input
          type="text"
          placeHolder="메일 주소 입력"
          name="todoItem2"
          value={recItem}
          onChange={e => {
            setRecItem(e.currentTarget.value);
          }}
          onKeyDown={handleKeyPress_rec}
          onBlur={handleBlur_rec}
          className="form-control"
        />
        </li>*/}
        </ul> 
        

        <div className="input-group-append">
          <button type="button" onClick={handleAdd_rec}  hidden>
            Add Todo
          </button>
        </div>
      </div>
      

        
    </td>
  </tr>

  <tr>  
    <td class="table_title2" nowrap="">
    <FaAddressBook id='' style={{cursor:'pointer'}} onClick={() => popupwindow("/contacts", "contacts", 800, 800)} alt=''>
      
      </FaAddressBook>
        참조
     
    </td>
    <td class="table_list2_left">
      <div className="input-group">
      <ul className="rec_list">{display_cc}

      <li style={{position:"relative", width:"100%"}} isHaveInputValue={isHaveInputValue} ref={ccRef}><input
          type='text'
          value={ccItem}
          onChange={e => {
            setCcItem(e.currentTarget.value);
            console.log("ccitem", e.currentTarget.value);
          }}
          onKeyUp={handleDropDownKey_cc}
          onKeyDown={handleKeyPress_cc}
          //onBlur={handleBlur_rec} 
        />
        
        {(ccItem && dropDownList.length > 0)&& (
        <DropDownBox className="dropdown_box">
          {dropDownList.length === 0 && (
            <DropDownItem></DropDownItem>
          )}
          {dropDownList.map((dropDownItem, dropDownIndex) => {
            return (
              <DropDownItem
                key={dropDownIndex}
                onClick={() => clickDropDownItem_cc(dropDownItem)}
                onMouseOver={() => setDropDownItemIndex(dropDownIndex)}
                className={
                  dropDownItemIndex === dropDownIndex ? 'selected' : ''
                }
              >
                {dropDownItem}
              </DropDownItem>
            )
          })}
        </DropDownBox>
      )}
      </li>

        </ul>

        <div className="input-group-append">
          <button type="button" onClick={handleAdd_cc}  hidden>
            Add Todo
          </button>
        </div>
      </div>
      

    </td>
  </tr>


  <tr>  
    <td class="table_title2" nowrap="">
      <FaAddressBook id='' style={{cursor:'pointer'}} onClick={() => popupwindow("/contacts", "contacts", 800, 800)} alt=''>
      
      </FaAddressBook>
        숨은 참조
    
    </td>
    <td class="table_list2_left">
      <div className="input-group">
      <ul className="rec_list">{display_bcc}


      <li style={{position:"relative", width:"100%"}} isHaveInputValue={isHaveInputValue} ref={bccRef}><input
          type='text'
          value={bccItem}
          onChange={e => {
            setBccItem(e.currentTarget.value);
          }}
          onKeyUp={handleDropDownKey_bcc}
          onKeyDown={handleKeyPress_bcc}
          //onBlur={handleBlur_rec} 
        />
        
        {(bccItem && dropDownList.length > 0)&& (
        <DropDownBox className="dropdown_box">
        {dropDownList.length === 0 && (
            <DropDownItem>해당하는 단어가 없습니다</DropDownItem>
          )}
          {dropDownList.map((dropDownItem, dropDownIndex) => {
            return (
              <DropDownItem
                key={dropDownIndex}
                onClick={() => clickDropDownItem_bcc(dropDownItem)}
                onMouseOver={() => setDropDownItemIndex(dropDownIndex)}
                className={
                  dropDownItemIndex === dropDownIndex ? 'selected' : ''
                }
              >
                {dropDownItem}
              </DropDownItem>
            )
          })}
        </DropDownBox>
      )}
      </li>

        </ul>

        <div className="input-group-append">
          <button type="button" onClick={handleAdd_bcc}  hidden>
            Add Todo
          </button>
        </div>
      </div>
      

    </td>
  </tr>
  <tr>
  <td class="table_title2" nowrap="">
    제목
  </td>
    <td class="table_list2_left">
    <div className="input-group" style={{width:"100%"}}>
    <input type="text" name="name" className="formInput" placeholder="제목을 입력하세요." style={{marginLeft:"10px", width:"100%"}} onChange={subjectChange} value={subject}></input>
    </div>
    </td>
  </tr>

  <tr>
  <td class="table_title2" nowrap="">
    첨부파일
  </td>
    <td class="table_list2_left">
    <div className="input-group"
      onDragEnter={handleDragStart}  // dragstart 핸들러 추가
      onDragLeave={handleDragEnd}  // dragend 핸들러 추가
      onDragOver={handleDragOver}
      onDrop={handleDrop}
      
      >
    <input type="file" id="files" name="files" style={{display:"none"}} ref={inputFileRef} onChange={fileChangedHandler} 
    
    
      multiple/>
    <button style={{cursor:'pointer'}} className="large_file_btn" onClick={()=>onBtnClick()}>
        파일 첨부
        </button>
        <button id='drftrgvlnbpewmcswmcs' style={{cursor:'pointer'}} className="large_file_btn" onClick={() => popupwindow("/large_file", "large file upload", 800, 400)} alt=''>
        대용량 파일 링크 첨부하기
        </button>
    </div>
   
    </td>
  </tr>

  <tr style={{height:"auto"}}>
  <td class="table_title2" nowrap="">
    
  </td>
    <td class="upload_file_list" style={{maxHeight:"200px", overflowY:"auto"}}>
  {fileList.map((file,index) => 
    <div className="file_item">
      <p>{file.name} {formatBytes(file.size)}</p>
      <button type="button" className="remove" onClick={() => {removeFile(index);}}>x</button>
    </div>
    
  )} 

{largeFiles.map((file,index) => 
    <div>
      <p>{file.name} {formatBytes(file.size)} <span>다운로드 가능 기간</span></p>
      
      <button type="button" onClick={() => {removeFile(index);}}>Remove</button>
    </div>
    
  )} 
    </td>
  </tr>
      </table>
     <div id="response">{links}</div>
 
     <Editor
        ref={editorRef} // DOM 선택용 useRef
        initialValue=" "
        previewStyle="vertical" // 미리보기 스타일 지정
        height="500px" // 에디터 창 높이
        initialEditType="wysiwyg" //
        toolbarItems={[
          // 툴바 옵션 설정
          ['heading', 'bold', 'italic', 'strike'],
          ['hr', 'quote'],
          ['ul', 'ol', 'task', 'indent', 'outdent'],
          ['table', 'image', 'link'],
          ['code', 'codeblock']
        ]}
        plugins={[colorSyntax]} 
        useCommandShortcut={false} // 키보드 입력 컨트롤 방지
        hooks={{
          addImageBlobHook: async (blob, callback) => {
            console.log(blob.size);
            if (blob.size > 5000000) {
              alert(
                "업로드에 실패했습니다. 이미지당 5MB까지 업로드할 수 있습니다."
              );
              return;
            }
            const formData = new FormData();
                    formData.append("img", blob);
                    
                    // 1. 첨부된 이미지 파일을 서버로 전송후, 이미지 경로 url을 받아온다.
                    // const imgUrl = await .... 서버 전송 / 경로 수신 코드 ...
                    await axios.post(
                      "https://gw.thegmmedical.com:5003/api/img",
                      formData
                    ).then(( res ) => {
                      console.log(res);
                      console.log(                        "성공 시, 백엔드가 보내주는 데이터",
                        res.data.url
                      );
  
                      //const IMG_URL = encodeURI("https://gw.thegmmedical.com"+res.data.url);

                      const IMG_URL = "https://gw.thegmmedical.com"+res.data.url;
                      setImgLink(IMG_URL);
                      // 2. 첨부된 이미지를 화면에 표시(경로는 임의로 넣었다.)
                      callback(IMG_URL, "test");
                      //alert(IMG_URL);
                      files.push(IMG_URL);
                      setArray(files);
                      //setArray((fileArray) => [...fileArray, IMG_URL]);
                      //alert("fileArray: ", files);
                      console.log(files);
                      var arrayLength = files.length;
                      //alert(arrayLength);
                      //alert("end");
                    
                    });
            //callback('https://gw.thegmmedical.com:5003/img/카레유.png', '카레유');
          }
        }}
      ></Editor>
      <div className="button_wrap">
      <button className="submit" onClick={handleRegisterButton}>등록</button>
      {/*</form>*/}
      </div>
    </div>
)}
    </div>
    </div>
    </div>
  );
}