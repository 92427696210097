
import { NavLink, Link,useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import React, { useState, useEffect, useRef } from "react";
import LineListUpdate from './LineListUpdate';
import './css/view.css';
import { AiFillPrinter } from "react-icons/ai";
import DocList from "../components/side/DocList";
import LeaveView from "../components/LeaveView";
import OTView from "../components/OTView";
import CancelLeaveView from "../components/CancelLeaveView";
import userPic from "../components/img/user.png";
import { RiAttachmentFill } from "react-icons/ri";

import DocExcel from "../components/DocExcel";





/*
import Order from "./forms/OrderView";
import Confirm from "./forms/ConfirmView";
import Request from "./forms/RequestView";
import Repair from "./forms/RepairView";
import Rent from "./forms/RentView";
import Service from "./forms/ServiceView";
import Endoscope from "./forms/EndoscopeView";
import Inspect from "./forms/InspectView";
import Acquire from "./forms/AcquireView";
import HR from "./forms/HRView";
import Purchase from "./forms/PurchaseView";


import LogiOrder from "./logistics/OrderView";

import Expenditure from "./common/ExpenditureView";
import Receipt from "./common/ReceiptView";

*/

import Sign from "../components/Sign";

import Order from "./forms/OrderSign";
import Confirm from "./forms/ConfirmSign";
import Request from "./forms/RequestSign";
import Repair from "./forms/RepairSign";
import Rent from "./forms/RentSign";
import Service from "./forms/ServiceSign";
import Endoscope from "./forms/EndoscopeSign";
import Inspect from "./forms/InspectSign";
import Acquire from "./forms/AcquireSign";
import HR from "./forms/HRSign";
import Purchase from "./forms/PurchaseView";
import LogiOrder from "./logistics/OrderSign";

import Expenditure from "./common/ExpenditureSign";
import Receipt from "./common/ReceiptSign";



import { Tooltip } from "react-tooltip";
import { BiInfoCircle } from "react-icons/bi";
import { Sheet, Op, Selection, colors } from "@fortune-sheet/core";
import { Workbook, WorkbookInstance } from "@fortune-sheet/react";
import { GiHamburgerMenu } from "react-icons/gi";
import SendToBoard from "../components/SendToBoard";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import "@fortune-sheet/react/dist/index.css"
import initial from "./data/cell";
import ExcelJS from 'exceljs'
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
import { useReactToPrint } from 'react-to-print';
import ShowComment from "../components/ShowComment";
import { useStateWithCallbackLazy } from 'use-state-with-callback';


let luckysheet;
axios.defaults.withCredentials = true; 
const initialValues = {
  subject: "",
  user_no: "",
  user_name: "",
  form_group :"",
  form_no:"",
  form_name:"",
  cc: "",
  desc:"",
  recipient:"",
  expire:"1",
  security:"1",
 
};


const DocView = () => {
  const luckyCss = {
    margin: '0px',
    padding: '0px',
    width: '100%',
    height: '100%',
    left: '0px',
  }

  const location = useLocation();

  const [line, setLine] = useState();
  const [sidebar, setSidebar] = useState(false);
  const [currentApprover, setCurrentApprover] = useState();
  const [writerSignLink, setWriterSignLink] = useState([]);
  const [user, setUser] = useState([]);
  const [data, setData] = useStateWithCallbackLazy([]);

  const [loggedInUser, setLoggedInUser] = useState([]);


  const [test, setTest]= useState();
  const [lineList, setLineList] = useState([]);
  const [lineName, setLineName] = useState("");
  const [lineNo, setLineNo] = useState();
  const [writerName, setWriterName] = useState("");
  const [writerJob, setWriterJob] = useState("");
  const [writerDepartment, setWriterDepartment] = useState("");

  const [name, setname]= useState("");
  const [, updateState] = React.useState();
  const [divv, setDivv] = useState();
  const [showPopup, setPopup] = useState(false);
  const [link, setLink] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [editLine, setEditLine] = useState(false);
  const [fileDiv, setFileDiv] = useState();


  const [comments, setComments] = useState([]);
  const [comment, setComment] = useState();
  const [id, setId] = useState(1);
  const [updateIndex, setUpdateIndex] = useState(0);
  const [updateComment, setUpdateComment] = useState("");
  const [isExcel, setIsExcel]= useState(false);
  const [showExcel, setShowExcel] = useState(false);

  const [commentArray, setCommentArray] = useState([
    {
      id: 0,
      content: "",
    },
  ]);

 
  const [width, setWidth] = useState(window.innerWidth);


  function handleWindowSizeChange() {
      setWidth(window.innerWidth);
  }
  useEffect(() => {
      window.addEventListener('resize', handleWindowSizeChange);
      return () => {
          window.removeEventListener('resize', handleWindowSizeChange);
      }
  }, []);

 const isMobile = width <= 768;


  const toggleExcel = () => {
    if (showExcel===false){
      document.documentElement.requestFullscreen({ navigationUI: 'hide' });
    }else{
      document.documentElement.requestFullscreen({ navigationUI: 'show' });


    }

    setShowExcel(!showExcel);
  };

  const toggleOverlay = () => {
    setIsOpen(!isOpen);
  };
  const togglePopup = () => {
    setPopup(!showPopup);
  };
  const toggleLine = () => {
    setEditLine(!editLine);
  };

  //const [shData, setShData]= useState();
  const [dddd, setDDDD] = useState();
  const [absent, setAbsent] = useState([]);
  var shData=[];
  const navigate = useNavigate();
  function nextChar(c) {
    return String.fromCharCode(c.charCodeAt(0) + 1);
  }

  function formatBytes(bytes, decimals = 2) {
    if (!+bytes) return '0 Bytes'
  
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  
    const i = Math.floor(Math.log(bytes) / Math.log(k))
  
    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
  }

  
  const itscell2 = {
    name: "Sheet1",
    config: {
      
     
      
      columnlen: {
        "0": 131,
        "2": 131,
        "3": 131,
        "4": 131,
        "5": 131,
        "6": 131,
        "7": 131,
        "8": 131,
     
      },
      
   
    },
    id: "0",
    zoomRatio: 1,
    order: "0",
    column: 8,
    row: 68,
    status: 1,
    ch_width: 2361,
    rh_height: 936,
    celldata:[],
    luckysheet_select_save: [
      {
        left: 741,
        width: 138,
        top: 796,
        height: 19,
        left_move: 741,
        width_move: 138,
        top_move: 796,
        height_move: 19,
        row: [33, 33],
        column: [6, 6],
        row_focus: 33,
        column_focus: 6
      }
    ],
    /*calcChain: [
      {
        r: 0,
        c: 3,
        id: "0",
        func: [true, 3, "=Formula!A1+Formula!B1"],
        color: "w",
        parent: null,
        chidren: {},
        times: 0
      }
    ],*/
    scrollLeft: 0,
    scrollTop: 0
  };


  useEffect(()=>{
    window.addEventListener("load",function() {
      setTimeout(function(){
          // This hides the address bar:
          window.scrollTo(0, 1);
      }, 0);
  });
  
  
  
  
  
  
    
axios.get('https://gw.thegmmedical.com:5003/api/get_comment_doc/'+ location.state.doc_no)
      .then(function (response){
        console.log("get_comment", response.data);
        setComments(response.data);
   
      })
      .catch(function (error){
        console.log(error);
      })
      .then(function (){
      //always executed
      });

    axios.get('https://gw.thegmmedical.com:5003/api/getuser')
    .then(function (response){
      setLoggedInUser(response.data[0]);
  
    })
    .catch(function (error){
    console.log(error);
    })
    .then(function (){
    //always executed
    });

    axios.get('https://gw.thegmmedical.com:5003/api/get_current_approver/'+location.state.doc_no)
    .then(function (response){
      let result = response.data[0];
      console.log("hihidf", currentApprover);
      if (response.data.length>0){
        if (result.user_no == result.userNo){
          console.log("hihi", result.length);
          setCurrentApprover(result);
        }
      }

    });
    
    axios.get('https://gw.thegmmedical.com:5003/api/docview/'+location.state.doc_no)
    .then(function (response){
      console.log("docview,,,,,,,,,,,", response.data);
      setWriterName(response.data[0].name);
      setWriterJob(response.data[0].job_name);
      setWriterDepartment(response.data[0].d_name);
      setWriterSignLink(response.data[0].sign_link);
      setCustomName(response.data[0].custom_name);

    axios.get('https://gw.thegmmedical.com:5003/api/getuser/'+location.state.doc_no)
    .then(function (res){
      let userNo = res.data[0].user_no;
      setUser(res.data[0]);

      setUserNo(res.data[0].user_no);


      axios.get('https://gw.thegmmedical.com:5003/api/getdocline/'+location.state.doc_no)
        .then(function (l){
        
          var result=l.data;
        for (let i=0;i<l.data.length;i++){
          if (l.data[i].status === 4){
            result[l.data.length-1].sign_img = l.data[i].sign_img;
            console.log("heyhey");
            console.log("line list....", result);
          }
        }
        console.log("line...", result);
        let lineUsers = result.map(value => value.user_no);

        setLineList(result);
        axios.get('https://gw.thegmmedical.com:5003/api/get_security/'+response.data[0].security)
      .then(function (r){
        console.log("secu", r.data);
        if (r.data.length === 0){
          setData(r.data[0], () => {


            if (response.data[0].type ==="excel" && response.data[0].form_type!=="fortune"){
              setIsExcel(true);
            }
  
          if (response.data[0].form_data !== null && response.data[0].form_data !== "undefined" && response.data[0].form_data !==""
          && response.data[0].type ==="excel" && isMobile===false){
            var excel_data = JSON.parse(response.data[0].form_data);
            excel_data.showGridLines = 0;
            var rowN = excel_data.row;
            var height= parseInt(rowN) * 15;
            height = height + "px";
            height = height.toString();
            console.log(height, excel_data);
            console.log("excel_data...", excel_data);
            if (response.data[0].form_type==="fortune"){
            setDivv(<div style={{width:"100vh", height:"600px", overFlow:"visible"}}><Workbook data={[excel_data]} showToolbar={false} showFormulaBar={false} showSheetTabs={false} allowEdit={false} rowHeaderWidth={0} columnHeaderHeight={0}/></div>) ;

            }else{
              const loadLuckysheet = async () => {
                try {
                  // Load Luckysheet dynamically
                  const luckysheetModule = await import('luckysheet');
                  console.log("luckysheetModule", );
                  excel_data[0].showGridLines="0";
    
    
                  luckysheet = luckysheetModule.default;
           
                  luckysheet.create({
                    container: "luckysheet",
                    title: 'Luckysheet sphinx Demo', // set the name of the table
                    data: excel_data,
                    showinfobar: false,
                    allowUpdate :false,
                    showstatisticBar:false,
                    sheetFormulaBar:false,
                    columnHeaderHeight:0,
                    rowHeaderWidth:0,
                    showsheetbar:false,
                    showtoolbar:false,
                })
                 
           
           
                  // Set the loaded state
                } catch (error) {
                  console.error('Error loading Luckysheet:', error);
                }
              };
              loadLuckysheet();
            }
      
          
  
          }else{
            setDivv(<div></div>)
          }
          if (response.data[0].form_no === 1){
            console.log("1입니다");
            setDivv(<LeaveView docNo = {response.data[0].doc_no} userNo = {response.data[0].user_no}/>)
          }else if (response.data[0].form_no === 2){
            setDivv(<OTView docNo = {response.data[0].doc_no} userNo = {response.data[0].user_no}/>)
          }else if (response.data[0].form_no ===3){
            setDivv(<CancelLeaveView docNo = {response.data[0].doc_no} userNo = {response.data[0].user_no}/>)
          }
        });
        
        }else{
          let userNos = r.data.map(value => value.user_no);
          if (!userNos.includes(userNo) && response.data[0].user_no !== userNo
          && !lineUsers.includes(userNo)){
            alert("열람할 수 없습니다");
            window.history.go(-1);
          }else{

            if (response.data[0].type ==="excel" && response.data[0].form_type!=="fortune"){
              setIsExcel(true);
            }
  
            setData(response.data[0], () => {

            if (response.data[0].form_data !== null && response.data[0].form_data !== "undefined" && response.data[0].form_data !==""
            && response.data[0].type ==="excel" && isMobile===false){

              var excel_data = JSON.parse(response.data[0].form_data);
              excel_data.showGridLines = 0;
              /*height = height + "px";
              height = height.toString();
              console.log(height, excel_data);*/
              console.log("excel_data...", excel_data);
              if (response.data[0].form_type === "fortune"){
              setDivv(<div style={{width:"100vh", height:"600px", overFlow:"visible"}}><Workbook data={[excel_data]} showToolbar={false} showFormulaBar={false} showSheetTabs={false} allowEdit={false} rowHeaderWidth={0} columnHeaderHeight={0}/></div>) ;

              }else{
                const loadLuckysheet = async () => {
                  try {
                    // Load Luckysheet dynamically
                    const luckysheetModule = await import('luckysheet');
                    console.log("luckysheetModule", );
                    excel_data[0].showGridLines="0";
      
      
                    luckysheet = luckysheetModule.default;
             
                    luckysheet.create({
                      container: "luckysheet",
                      title: 'Luckysheet sphinx Demo', // set the name of the table
                      data: excel_data,
                      showinfobar: false,
                      allowUpdate :false,
                      showstatisticBar:false,
                      sheetFormulaBar:false,
                      columnHeaderHeight:0,
                      rowHeaderWidth:0,
                      showsheetbar:false,
                      showtoolbar:false,
                  })
                   
             
             
                    // Set the loaded state
                  } catch (error) {
                    console.error('Error loading Luckysheet:', error);
                  }
                };
                loadLuckysheet();
              }
        
              
            }else{
              setDivv(<div></div>)
            }
            if (response.data[0].form_no === 1){
              setDivv(<LeaveView docNo = {response.data[0].doc_no} userNo = {response.data[0].user_no}/>)
            }else if (response.data[0].form_no === 2){
              setDivv(<OTView docNo = {response.data[0].doc_no} userNo = {response.data[0].user_no}/>)
            }else if (response.data[0].form_no ===3){
              setDivv(<CancelLeaveView docNo = {response.data[0].doc_no} userNo = {response.data[0].user_no}/>)
            }
          });
          
          }
        }
    
    
      })
      .catch(function (error){
      console.log(error);
      })
      .then(function (){
      //always executed
      });
        
        })
        .catch(function (error){
        console.log(error);
        })
        .then(function (){
        //always executed
        });
    
    
    })
    .catch(function (error){
    console.log(error);
    })
    .then(function (){
    //always executed
    });
      
    
    
    })
    .catch(function (error){
    console.log(error);
    })
    .then(function (){
    //always executed
    });

    

    
    
   
    
    

    

    axios.get('https://gw.thegmmedical.com:5003/api/checkabsent')
    .then(function (response){
      console.log("absent is", response.data);
      var user_nos = response.data.map(function(item) {
        return item['user_no'];
      })
    console.log("absent...", user_nos);
    setAbsent(user_nos);

    
    
  
    })
    .catch(function (error){
    console.log(error);
    })
    .then(function (){
    //always executed
    });
   
    const getFiles = async() =>{
      axios.get('https://gw.thegmmedical.com:5003/api/doc_files/'+location.state.doc_no)
      .then(function (response){
        console.log("hey dddddd is!!!!!!!!!!!!", response.data);
        var files = response.data;
        var file_divs =[];
        for (let i=0;i<files.length;i++){
          var pieces = files[i].filename.split(" ");
          var file_div = <div><a href={"https://gw.thegmmedical.com/uploads/doc/"+location.state.doc_no +"/"+files[i].filename} download>{files[i].filename} &nbsp;&nbsp;&nbsp;
              <span className="attachment_size">{formatBytes(files[i].size)} Bytes</span></a> &nbsp;&nbsp;&nbsp;
                  { (pieces[pieces.length-1] === "docx" || pieces[pieces.length-1] === "doc" || pieces[pieces.length-1] === "xlsx") && (
                  <button onClick={()=>{toggleOverlay(); setLink("https://gw.thegmmedical.com/uploads/doc/"+location.state.doc_no +"/"+files[i].filename);}}>오피스뷰어</button>
      )}
          </div>;
          file_divs.push(file_div);
        }
        setFileDiv(file_divs);
        //setFileList(response.data);
    
      })
      .catch(function (error){
      console.log(error);
      })
      .then(function (){
      //always executed
      });
    }


    getFiles();
}, []);






const [fileSize, setFileSize] = useState("");
const [largeFiles, setLargeFiles] = useState([]);
const [fileList, setFileList] = useState([]);
const fileChangedHandler = (event) => {
  //let file_size = event.target.files[0].size;

  var newFiles = [];
  const LargeFs = [];
  var file__size=0;
  var fs = 0;
  for(let i = 0; i < event.target.files.length; i++){
    file__size+=event.target.files[i].size;
   
    //filesize+=event.target.files[i].size;
    console.log(fileSize);
    if ((fileSize+file__size)>20971520 || file__size > 20971520){
      LargeFs.push(event.target.files[i]);
     
     
    }else{
     
      fs+=event.target.files[i].size;
      newFiles.push(event.target.files[i]);
     
    }
  }
  setFileSize(fileSize+fs);
  var newArray = fileList.concat(newFiles);
  setLargeFiles(LargeFs);
  setFileList(newArray);
  console.log(largeFiles);

};
const inputFileRef = useRef();






const addComment = () => {
setId(id + 1);


if (comment === "" || comment === undefined){
  alert("내용을 입력해주세요");
  return;
}


const formData = new FormData();
 
for (let i = 0; i<fileList.length;i++){
console.log(fileList[i].name);
formData.append(`file[${i}]`, fileList[i]);
formData.append(`filename[${i}]`, fileList[i].name);
}
for (let i = 0; i<largeFiles.length;i++){
formData.append(`lfile[${i}]`, largeFiles[i]);
formData.append(`lfilename[${i}]`, largeFiles[i].name);
}
formData.append(`id`, id);
formData.append(`doc_no`, location.state.doc_no);
formData.append(`comment`, comment);









const newComment = {
  id: id,
  doc_no : location.state.doc_no,
  content: comment,
 
};
setCommentArray([...commentArray, newComment]);


axios.post('https://gw.thegmmedical.com:5003/api/add_comment_doc', formData, {
  headers: {
    Accept: "application/json",
    "Content-Type": "multipart/form-data",
  },
})
.then(function (response){
  console.log(response);
  window.location.reload(true);
})
.catch(function (error){
  console.log(error);
})
.then(function (){
  //always executed
});

};




function updateCmt(cmtNo){
const newComment = {
no: cmtNo,
doc_no : location.state.doc_no,
content: updateComment





};
axios.post('https://gw.thegmmedical.com:5003/api/update_comment_doc', newComment)
.then(function (response){
console.log(response);
window.location.reload(true);
})
.catch(function (error){
console.log(error);
})
.then(function (){
});
}








function deleteCmt(cmtNo){
const data = {
no: cmtNo,
doc_no : location.state.doc_no,

};
axios.post('https://gw.thegmmedical.com:5003/api/delete_comment_doc', data)
.then(function (response){
console.log(response);
window.location.reload(true);




})
.catch(function (error){
console.log(error);
})
.then(function (){
});
}









  const itscell = {
    name: "Sheet1",
    config: {
      
     
      
      columnlen: {
        "0": 131,
        "2": 131,
        "3": 131,
        "4": 131,
        "5": 131,
        "6": 131,
        "7": 131,
        "8": 131,
     
      },
      
   
    },
    id: "0",
    zoomRatio: 1,
    order: "0",
    column: 8,
    row: 68,
    status: 1,
    ch_width: 2361,
    rh_height: 936,
    celldata:[],
    luckysheet_select_save: [
      {
        left: 741,
        width: 138,
        top: 796,
        height: 19,
        left_move: 741,
        width_move: 138,
        top_move: 796,
        height_move: 19,
        row: [33, 33],
        column: [6, 6],
        row_focus: 33,
        column_focus: 6
      }
    ],
    /*calcChain: [
      {
        r: 0,
        c: 3,
        id: "0",
        func: [true, 3, "=Formula!A1+Formula!B1"],
        color: "w",
        parent: null,
        chidren: {},
        times: 0
      }
    ],*/
    scrollLeft: 0,
    scrollTop: 0
  };


  function popupwindow(url, title, w, h) {
    var left = (window.screen.width/2)-(w/2);
    var top = (window.screen.height/2)-(h/2);
    return window.open(url, title, 'toolbar=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width='+w+', height='+h+', top='+top+', left='+left);
  } 

  window.addEventListener('message', (event) => {
    if (event.data["line_no"] !== undefined){
      var line_no = event.data["line_no"];
      var line_name = event.data["line_name"];
      var line_list = event.data["line_list"];
      
      setLineNo(line_no);
      setLineName(line_name);
      setLineList(line_list);
    }

   
  });
////////////////////////////////////////////////
// EXCEL IMPORT
////////////////////////////////////////////////



const componentRef = useRef();
const formRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const formPrint = useReactToPrint({
    content: () => formRef.current,
  });

  const [showComment, setShowComment] = useState(false);
  const [commentData, setCommentData] = useState([]);
  const toggleComment = () => {
    setShowComment(!showComment);
  };
    
  const cancel = () => {
    console.log("hihi");

    axios.get("https://gw.thegmmedical.com:5003/api/cancel_doc/"+ location.state.doc_no)
    .then(function (response){
      console.log(response);
      alert("회수되었습니다.");
      window.location.href="https://gw.thegmmedical.com/doc_main";
      
    })
    .catch(function (error){
      console.log(error);
    })
    .then(function (){
      //always executed
    });

  }

  const [showSign, setShowSign] = useState(false);
  const toggleSign = () => {

    setShowSign(!showSign);
  
    
    //
  };

  const [customName, setCustomName] = useState("");
  const [orderNo, setOrderNo]= useState();
  const [userNo, setUserNo]= useState();

  const openSign = () =>  {
    if (data.secured === 1){
      let pw = prompt("결재 암호를 입력하세요", "");
      if (pw === user.e_sign_pw){
        toggleSign();
      }else{
        alert("암호가 일치하지 않습니다.")
      }
      
    }else{
      toggleSign();
    }
  }

  const updatePrint = () =>{
    axios.get("https://gw.thegmmedical.com:5003/api/doc_print/"+ location.state.doc_no)
    .then(function (response){
      console.log(response);
     
    })
    .catch(function (error){
      console.log(error);
    })
    .then(function (){
      //always executed
    });
  }

  function checkExtension(filename){
    var parts = filename.split('.');
    var extension = parts[parts.length-1];
    if (extension === "doc" || extension === "docx" || extension === "docm" || extension === "dot" || extension === "dotx"
    || extension === "odt" || extension === "xls" || extension === "xlsx" || extension === "xlsm"|| extension === "xlsb"
    || extension === "ppt"|| extension === "pptm"|| extension === "pptx"){
      return true;
    }else{
      return false;
    }
  }


  const onBtnClick = () => {
      /*Collecting node-element and performing click*/
    inputFileRef.current.click();
  };
  return (
    <div className="wrapper">
{showPopup ? (
  <SendToBoard doc_no = {location.state.doc_no} closePopup={() => togglePopup()} />
) : null}
<div className="sidebar-icon" style={{margin:"0 auto"}} onClick={()=>{if (sidebar === false){document.getElementsByTagName("main")[0].style.overflow="hidden"}else{document.getElementsByTagName("main")[0].style.overflow="auto"} setSidebar(!sidebar);}}>
        <GiHamburgerMenu/>
      </div>
      {sidebar && (
        <div className="side-mobile-menu">
        <DocList/>
        </div>
      )}
<div className="content-wrap">


<div style={{display: editLine ? 'block' : 'none' }}>

{editLine ? (
  <LineListUpdate doc_no = {location.state.doc_no} data={lineList} lineName={lineName} lineNo={lineNo} closePopup={() => toggleLine()} onChange={(value)=>{
    

    const data ={

      line_list : JSON.stringify(value.line_list),
      doc_no : location.state.doc_no
   
    }
    var result = window.confirm("결재 라인을 변경하시겠습니까?");
    if(result){
      axios.post('https://gw.thegmmedical.com:5003/api/edit_docline', data)
      .then(function (response){
        console.log(response);
        alert("변경되었습니다.");
        var line_no = value.line_no;
    var line_name = value.line_name;
    var line_list = value.line_list;
    
   


    setLineNo(line_no);
    setLineName(line_name);
    setLineList(response.data.data);

    setEditLine(false);

  
      })
      .catch(function (error){
        console.log(error);
      })
      .then(function (){
        //always executed
      });
    }
    
  }}/>
) : null}
</div>
{showComment ? (
  <ShowComment commentData={commentData} closePopup={() => toggleComment()} />
) : null}

    <div className="outer_wrapper docview" style={{justifyContent:"flex-start", width:"100%", height:"100% !important"}}>
        <div className="side-menu">
          <DocList/>
        </div>
    

    <div className="docwrite">
  
        {customName === "" && 
  showSign && absent.includes(loggedInUser.user_no)? (
  <Sign custom_name = {customName} docNo= {location.state.doc_no} formNo={data.form_no} subject= {data.subject} orderNo = {orderNo} line={line} decider = {user} userNo = {userNo} closePopup={() => toggleSign()} />
  ) : null}
{showSign && !absent.includes(loggedInUser.user_no)? (
  <Sign custom_name = {customName} docNo= {location.state.doc_no} formNo={data.form_no} subject= {data.subject} orderNo = {orderNo} line={line} decider = {undefined} userNo = {userNo} closePopup={() => toggleSign()} />
  ) : null
}
{customName === "hr" && 
   showSign && absent.includes(loggedInUser.user_no)? (
    <Sign custom_name = "hr" docNo= {location.state.doc_no} formNo={data.form_no} subject= {data.subject} orderNo = {orderNo} line={line} decider = {user} userNo = {userNo} closePopup={() => toggleSign()} />
    ) : null}
  {showSign && !absent.includes(loggedInUser.user_no)? (
    <Sign custom_name = "hr" docNo= {location.state.doc_no} formNo={data.form_no} subject= {data.subject} orderNo = {orderNo} line={line} decider = {undefined} userNo = {userNo} closePopup={() => toggleSign()} />
    ) : null
}





{showExcel ? (
  <DocExcel doc_no = {location.state.doc_no} closePopup={() => {toggleExcel();
   
  }} />
) : null}

    <div className="docs_view">
            
      <div className="doc_top">
        <h4 style={{padding:"0 10px"}}>문서 보기 </h4>
        <div className="doc_buttons">
          <div className="btn" onClick={()=>{handlePrint();updatePrint();}}><AiFillPrinter/>인쇄</div>
          <div className="btn" onClick={formPrint}><AiFillPrinter/>양식 인쇄</div>


          {(data.status == "4" && (<div className="btn" onClick={()=>navigate("/doc_rw", {state: {data: data}})}>기결재상신</div>))}

          {(data.status != "4" && data.status != 0) && (
            <div className="btn" onClick={()=>cancel()}>결재회수</div>
          )}
          {(data.status != "4" && currentApprover!== undefined) && (<div style={{display:"flex"}}>
            {currentApprover.line == 1 && (       
          <div className="btn" onClick={()=>toggleLine()}>결재라인 수정</div>
          )}

            {currentApprover.doc == 1 && (          
              <Link to ="/doc_u" state={{data: data}}>문서 수정</Link>
            )} 

          </div>)}
          <div className="btn" onClick={()=>togglePopup()}>게시판에 등록</div>

          <Link to={location.state.href}>목록</Link>
        </div>
      </div>
          <div style={{background:"#fff", padding:"0 10px"}} ref={componentRef}>
          <div className="doc_header">
            <h3>{data.form_name}</h3>
           

{(lineList.length >0)  && (
            <div style={{display:"flex"}} className="line">


<table>

<tr className="name"><td rowSpan={5} className="type">작성자</td><td>{user.job_name}</td></tr>
<tr className="name"><td>{user.d_name}</td></tr>
<tr className="name"><td>{user.name}</td></tr>

<tr><td className=""><img src={user.sign_link} alt="preview" style={{width:"40px"}}/></td></tr>

<tr><td className="approved_date">{data.submit_date}</td></tr>
</table>

 {lineList.map((element, index)=> (
(() => {
if (element.status !==1 && element.user_no === loggedInUser.user_no && element.status !==5 && element.status !==4 && element.next == 1) {
return (
<table>
<tr className="name"><td rowSpan={5} className="type">{element.type}</td><td>{element.job_name}</td></tr>
<tr className="name"><td>{element.d_name}</td></tr>
<tr className="name"><td className=""><div>{element.name}</div>
</td>
</tr>
<tr>
<td>
<div style={{height:"40px"}}><button onClick={() => {openSign(); setLine(element.type);console.log("??");setOrderNo(element.order_no)}}>결재</button></div>
</td></tr>
<tr></tr>
</table>
)} else if(element.status === 4 && element.last!==1) {
  return (
    <table>
<tr className="name"><td rowSpan={5} className="type">전결</td><td>{element.job_name}</td></tr>
<tr className="name"><td>{element.d_name}</td></tr>
<tr className="name"><td><div>{element.name}</div></td></tr>
<tr><td>
<div style={{height:"40px"}}>전결</div>
</td></tr>
<tr><td className="approved_date">{element.approved_date}</td></tr>

<tr></tr>
</table>)
        } else if(element.status === 3) {
          return (
          <table>
<tr className="name"><td rowSpan={5} className="type">{element.type}</td><td>{element.job_name}</td></tr>
<tr className="name"><td>{element.d_name}</td></tr>
<tr className="name"><td><div>{element.name}</div></td></tr>
<tr><td>
<div style={{height:"40px"}}>반려</div>
</td></tr>
<tr><td className="approved_date">{element.approved_date}</td></tr>

<tr></tr>
</table>)
        } else if(element.status === 2) {
          return (
          <table>
<tr className="name"><td rowSpan={5} className="type">{element.type}</td><td>{element.job_name}</td></tr>
<tr className="name"><td>{element.d_name}</td></tr>
<tr className="name"><td><div>{element.name}</div></td></tr>
<tr><td>
<div style={{height:"40px"}}>보류</div>
</td></tr>
<tr><td className="approved_date">{element.approved_date}</td></tr>

<tr></tr>
</table>)
        } else if (element.status != 1 && element.user_no === loggedInUser.user_no && element.next != 1) {
          return (
            <table>
            <tr className="name"><td rowSpan={5} className="type">{element.type}</td><td>{element.job_name}</td></tr>
            <tr className="name"><td>{element.d_name}</td></tr>
            
            <tr className="name"><td><div>{element.name}</div></td></tr>
            <tr><td>
            <div style={{height:"40px"}}>대기중</div>
            </td></tr>
            <tr></tr>
            </table>
          )        
        } else if(element.status === 0 && element.user_no !== loggedInUser.user_no && !absent.includes(element.user_no)) {
          return (
          <table>
<tr className="name"><td rowSpan={5} className="type">{element.type}</td><td>{element.job_name}</td></tr>
<tr className="name"><td>{element.d_name}</td></tr>

<tr className="name"><td><div>{element.name}</div></td></tr>
<tr><td>
<div style={{height:"40px"}}>대기중</div>
</td></tr>
<tr></tr>
</table>)
        } else if (element.status === 5 && element.user_no === loggedInUser.user_no) {
          return (
            <table>
<tr className="name"><td rowSpan={5} className="type">{element.type}</td><td>{element.job_name}</td></tr>
<tr className="name"><td>{element.d_name}</td></tr>

<tr className="name"><td><div>{element.name}</div></td></tr>
<tr><td>
<div style={{height:"40px"}}><button onClick={() => {openSign(); setLine(element.type);setOrderNo(element.order_no)}}>후결</button></div>
</td></tr>
<tr></tr>
</table>
          )

        
        } else if (element.status !==1 && absent.includes(element.user_no)) {
          return (
            <>
            <table>
<tr className="name"><td rowSpan={5} className="type">{element.type}</td><td>{user.job_name}</td></tr>
<tr className="name"><td>{element.d_name}</td></tr>

<tr className="name"><td><div>{user.name}</div></td></tr>
<tr><td>
<div style={{height:"40px"}}><button onClick={() => {openSign(); setLine(element.type); setUserNo(element.user_no);setOrderNo(element.order_no)}}>대결</button></div>
</td></tr>
<tr></tr>
</table>

<table>
<tr className="name"><td rowSpan={5} className="type">{element.type}</td><td>{element.job_name}</td></tr>
<tr className="name"><td>{element.d_name}</td></tr>

<tr><td className="slash"><div className="name"></div>

</td></tr>
<tr></tr>
</table>
</>
          )
        }
        else {
          return (
            <table>
<tr className="name"><td rowSpan={5} className="type">{element.type}</td><td>{element.job_name}</td></tr>
<tr className="name"><td>{element.d_name}</td></tr>

<tr className="name"><td><div>{element.name}</div></td></tr>

<tr><td>
<img src={element.sign_img} alt="preview" style={{width:"40px"}}/></td></tr>
<tr><td className="approved_date">{element.approved_date}</td></tr>
</table>
          )
        }
      })()

      ))}
      
            </div>
          )}
      
           
          </div>
          <div style={{display:"flex", width:"100%", justifyContent:"space-between", flexDirection:"column"}} className="form_wrap">
          

         
          <table className="form_write" style={{margin:"20px 0", width:"100%"}}>

      {/*<button onClick={()=>downloadEmployeeData()}>test...</button>*/}

      <tr className="table_row mobile">
<td class="table_title2" nowrap="">
  문서번호

</td>
  <td class="table_list2_left">
  <div className="input-group" style={{display:"flex", flexShrink:"0"}}>
  <span>{location.state.doc_no}</span>
  </div>
  </td>
</tr>
      <tr>
<td class="table_title2" nowrap="">
  제목
</td>
  <td class="table_list2_left">
  <div className="input-group">
  <span type="text" name="subject" className="formInput">{data.subject}</span>
  </div>
  </td>

  <td class="table_title2 pc" nowrap="">
  작성자

</td>
  <td class="table_list2_left pc">
  <div className="input-group" style={{display:"flex", flexShrink:"0"}}>
  <span>{data.user_name}</span>
  </div>
  </td>

</tr>      
      
     
<tr className="table_row mobile">
<td class="table_title2" nowrap="">
  작성자

</td>
  <td class="table_list2_left">
  <div className="input-group" style={{display:"flex", flexShrink:"0"}}>
  <span>{data.user_name}</span>
  </div>
  </td>
</tr>


<tr>
<td class="table_title2" nowrap="">
  파일
</td>
  <td class="table_list2_left">
  <div className="input-group">
  {fileDiv}
 


  </div>
  </td>


</tr>      





<tr>
<Tooltip
        id="cc"
        place="bottom"
        >
          <div style={{display:"flex", flexDirection:"column", textAlign:"left"}}>
            <span>문서참조자는 결재문서와 결재상태를 확인할 수 있습니다.</span>
          </div>
        </Tooltip>

<td class="table_title2" nowrap="">
  문서참조자<BiInfoCircle data-tooltip-id="cc" style={{marginLeft:"5px"}}/>
</td>
<td class="table_list2_left" >
        <div className="input-group">
        <span type="text" name="subject" className="formInput"></span>
        {data.cc !==undefined&&(
          JSON.parse(data.cc).map(item => (
    <div className="admin"><li key={item.id}>
      {item.text}
    </li>

    </div>
      ))
        )}
      </div>
     
		</td>
</tr>

<tr>
<td class="table_title2" nowrap="">
  문서수신자
</td>
<td class="table_list2_left">
        <div className="input-group">
        <span type="text" name="subject" className="formInput"></span>
        {data.recipient !==undefined&&(
          JSON.parse(data.recipient).map(item => (
    <div className="admin"><li key={item.id}>
      {item.text}
    </li>

    </div>
      ))
        )}
      </div>
     
		</td>
</tr>
{/*
<tr>

<td class="table_title2" nowrap="">
  보관기간
</td>
  <td class="table_list2_left">
  <div className="input-group" style={{display:"flex", flexShrink:"0"}}>
  <span type="text" name="subject" className="formInput">{data.expire}년</span>

  </div>
  </td>
  <td class="table_title2 pc" nowrap="">
  보안등급
</td>
  <td class="table_list2_left pc">
  <div className="input-group" style={{display:"flex", flexShrink:"0"}}>
  <span type="text" name="subject" className="formInput">{data.security}등급</span>

  </div>
  </td>

</tr>


<tr className="table_row mobile">
<td class="table_title2" nowrap="">
  보안등급
</td>
  <td class="table_list2_left">
  <div className="input-group" style={{display:"flex", flexShrink:"0"}}>
  <span type="text" name="subject" className="formInput">{data.security}등급</span>

  </div>
  </td>
</tr>
*/}



    </table>



    {(isExcel===true && isMobile===true)&& (
  <button onClick={()=>toggleExcel()}>문서 내용 보기</button>
)}


   

    {data.type ==="custom" &&(
<div className="custom_form custom_form_view" style={{position:"relative"}} ref={formRef}>
  {/*<Workbook onOp={console.log} data={[initial]} onChange={(data)=>{console.log("changed, ", data);}} />*/}
 
  {(data.type ==="custom"  && data.custom_name==="order") && (
  <Order data={location.state.doc_no} line={lineList}/>)}

{(data.type ==="custom"  && data.custom_name==="confirm") && (
  <Confirm data={location.state.doc_no} line={lineList}/>)
}
{(data.type ==="custom"  && data.custom_name==="request") && (
  <Request data={location.state.doc_no} line={lineList}/>)
}
{(data.type ==="custom"  && data.custom_name==="repair") && (
  <Repair data={location.state.doc_no} line={lineList}/>)
}

{(data.type ==="custom"  && data.custom_name==="rent") && (
  <Rent data={location.state.doc_no} line={lineList}/>)
}
{(data.type ==="custom"  && data.custom_name==="service") && (
  <Service data={location.state.doc_no} line={lineList}/>)
}

{(data.type ==="custom"  && data.custom_name==="endoscope") && (
  <Endoscope data={location.state.doc_no} line={lineList}/>)
}

{(data.type ==="custom"  && data.custom_name==="inspect") && (
  <Inspect data={location.state.doc_no} line={lineList}/>)
}
{(data.type ==="custom"  && data.custom_name==="acquire") && (
  <Acquire data={location.state.doc_no} line={lineList}/>)
}
{(data.type ==="custom"  && data.custom_name==="hr") && (
  <HR data={location.state.doc_no} line={lineList}/>)
}
{(data.type ==="custom"  && data.custom_name==="purchase") && (
  <Purchase data={location.state.doc_no} line={lineList}/>)
}
{(data.type ==="custom"  && data.custom_name==="logi_order") && (
  <LogiOrder data={location.state.doc_no} line={lineList}/>)
}
{(data.type ==="custom"  && data.custom_name==="expenditure") && (
  <Expenditure data={location.state.doc_no} line={lineList}/>)
}
{(data.type ==="custom"  && data.custom_name==="receipt") && (
  <Receipt data={location.state.doc_no} line={lineList}/>)
}



</div>
    )}
     
     {/*}
     {data.type !=="custom" &&(<div className="custom_form custom_form_view" style={{height:"600px", overflow:"hidden", position:"relative"}} ref={formRef}>{divv}</div>)} 
*/}

{data.type ==="html" &&(<div className="custom_form custom_form_view" style={{height:"100%", overflow:"hidden", position:"relative"}} ref={formRef}>{divv}</div>)} 

     {(data.type ==="excel"&& data.form_type==="fortune")  &&(<div className="form_view custom_form_view" style={{position:"relative", height:"600px"}} ref={formRef}>
     {divv}
     
     </div>)} 

     {(data.type ==="excel"&& data.form_type!=="fortune"&& isMobile === false)  &&(<div className="form_view custom_form_view" style={{position:"relative", height:"600px"}} ref={formRef}>
     <div
     id="luckysheet"
     style={luckyCss}
   />     
     </div>)} 
   








     <div className="comment" style={{borderTop:"1px solid #ddd", borderBottom:"1px solid #ddd",
 
 boxSizing: "border-box", padding:"0px", margin:"5px"}}>


 <h3>의견내용</h3>


 <div className="comment_write">
 <div style={{border:"1px solid #ddd", padding:"10px", boxSizing:"border-box"}} className="comment_write_area">
   <div style={{display:"flex", flexDirection:"column"}}>
       <textarea className="comments-input" placeholder="내용 입력..."
       onChange={(e) => {setComment(e.target.value)}} style={{width:"100%", boxSizing:"border-box"}}/>
       
       <div style={{display:"flex", justifyContent:"space-between", marginTop:"5px"}}>
       <RiAttachmentFill onClick={()=>onBtnClick()} style={{color:"#46abde", fontSize:"17px"}}/>
        <button className="upload-button" onClick={addComment} style={{width:"60px", border:"none", background:"#a5d5ff"}}>
         게시
       </button>
       </div>
       
   </div>
   <input type="file" id="files" name="files" style={{display:"none"}} ref={inputFileRef} onChange={fileChangedHandler}/>
   <div style={{display:"flex",marginTop:"5px"}}>
   {fileList.map((file,index) =>
     <div className="file_item">
       <span>{file.name} {formatBytes(file.size)}</span>
       <button style={{background:"transparent"}} type="button" className="remove" onClick={() => {setFileList([])}}>x</button>
     </div>
   
     )}
   </div>
 </div>
<div className="comment_list" style={{marginTop:"5px", background:"#fff"}}>




 {comments.map((ele,idx)=>
   <div className="cmt" style={{borderTop:"1px solid #ddd", padding:"5px 0"}}>




   <div className="custom-file-upload" style={{display:"flex", alignItems:"center", width:"auto"}}>          
   {(ele.profile_link !=="" && ele.profile_link !== null) ?
   <div className="img-wrap img-upload" style={{width:'auto'}}><img for="photo-upload" src={ele.profile_link} alt="profile_pic" style={{width:"30px", height:"30px"}}/>
   </div>
   :
   <img for="photo-upload" src={userPic} alt="profile_pic" style={{width:"20px", height:"20px"}}/>
   }
   <span style={{marginLeft:"5px"}}>{ele.name} {ele.job_name} ({ele.write_date})</span>




   {ele.user_no === loggedInUser.user_no && (
     <div className="btns commentBtn" style={{justifyContent:"end"}}>
       <span onClick={()=>{setUpdateIndex(ele.no); setUpdateComment(ele.content);}}>수정</span>
       <span>|</span>
       <span onClick={()=>deleteCmt(ele.no)}>삭제</span>
     </div>




   )}




   </div>








   








   <div className="cmt_content">








   {updateIndex == ele.no ?
     <div className="comment_write" style={{display:"flex", marginTop:"10px"}}>
     <input className="comments-input" type="text" placeholder="댓글 수정..."
     onChange={(e) => {setUpdateComment(e.target.value)}} value={updateComment}
     style={{width:"calc(100% - 80px)"}}/>
     <button className="upload-button"  onClick={()=>updateCmt(ele.no)} style={{width:"50px"}}>
       수정
     </button>




     <button className="upload-button" onClick={()=>setUpdateIndex(0)} style={{width:"50px"}}>
       취소
     </button>
 </div>
   : <span style={{marginTop:"2px", display:"block"}}>{ele.content}
   {(ele.filename!==null && ele.filename!=="") && (
     <div>
       <a href={"https://gw.thegmmedical.com/uploads/doc_comment/"+ele.no +"/"+ele.filename} download>{ele.filename} &nbsp;&nbsp;&nbsp;
       </a>
       {checkExtension(ele.filename) && (
         <button onClick={()=>{toggleOverlay(); setLink("https://gw.thegmmedical.com/uploads/doc_comment/"+ele.no +"/"+ele.filename);}}
         style={{padding:"0",fontSize:"11px"}}>오피스뷰어</button>
       )}
     </div>
   )}
 
</span>}




   




 
   </div>
 


   








   </div>




 




 
 )}








</div>
</div>




</div>




    </div>

   

    </div>


    

  
    </div>
  </div>
  </div></div>

</div>
  );
};

export default DocView;
