import { NavLink, Link,useParams, useLocation, Navigate, useNavigate } from "react-router-dom";
import axios from "axios";
import React, { useState, useEffect, useRef, useMemo } from "react";
import MailNav from "../components/side/MailNav";
import './css/view.css';
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { RiFileExcel2Line, RiFileWord2Line } from "react-icons/ri";
import { IoMailOutline, IoPersonAddOutline, IoCopyOutline } from "react-icons/io5";
import { BiBlock } from "react-icons/bi";
import { BsReply,BsReplyAllFill, BsTrash3 } from "react-icons/bs";
import { useReactToPrint } from 'react-to-print';
import Overlay from "../components/Overlay";

import AddAddr from "../components/AddAddr";
import {GiHamburgerMenu} from "react-icons/gi";

import moment from "moment";
axios.defaults.withCredentials = true; 

const View = () => {
  const [toAddr, setToAddr]=useState("");

    const [data, setData]=useState(null);
    const [prev, setPrev]=useState([]);
    const [prevDate, setPrevDate]=useState("");
    const [nextDate, setNextDate]=useState("");
    const [sidebar, setSidebar] = useState(false);
    const [next, setNext]=useState([]);
    const [isActive, setActive]=useState(false);
    const [isToActive, setToActive]=useState(false);
    const [isCCActive, setCCActive]=useState(false);
    const [isBCCActive, setBCCActive]=useState(false);
    const [addrs, setAddrs] = useState([]);
    const [show, setShow]=useState(false);
    const [addr, setAddr]=useState([]);
    const [boxes, setBoxes]=useState("");
    const [move, setMove]=useState(false);
    const [clicked, setClicked]=useState("");

    const [unread, setUnread]=useState(true);
    const [htmlContent, setHtmlContent] = useState("");

    const { no, folder } = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    //const date = location.state.date;
    const [toggle, setToggle] = useState(true);
    const [toggle2, setToggle2] = useState(true);
    const [isOpen, setIsOpen] = useState(false);
    const [link, setLink]=useState("");
    const [user, setUser]=useState("");

    const toggleOverlay = () => {
      setIsOpen(!isOpen);
    };

    function tryParseJSONObject (jsonString){
      try {
          var o = JSON.parse(jsonString);
  
          // Handle non-exception-throwing cases:
          // Neither JSON.parse(false) or JSON.parse(1234) throw errors, hence the type-checking,
          // but... JSON.parse(null) returns null, and typeof null === "object", 
          // so we must check for that, too. Thankfully, null is falsey, so this suffices:
          if (o && typeof o === "object") {
              return o;
          }
      }
      catch (e) { }
  
      return false;
  };

    useEffect(() => {

      axios.get('https://gw.thegmmedical.com:5003/api/getuser')
  .then(function (response){
    //console.log(response);
    setUser(response.data[0]);
  })
  .catch(function (error){
    console.log(error);
  })
  .then(function (){
    //always executed
  });
      axios.get('https://gw.thegmmedical.com:5003/api/getboxes')
  .then(function (response){
    //console.log(response);
    setBoxes(response.data);
  })
  .catch(function (error){
    console.log(error);
  })
  .then(function (){
    //always executed
  });

      const msg = {
        folder: folder
      }
        
      axios.post('https://gw.thegmmedical.com:5003/api/prev_next/'+no, msg)
      .then(function (response){
        console.log("prev and next ", response.data); 
        let body  = document.getElementsByTagName("BODY")[0];let width = body.offsetWidth;
        if (response.data[0].prev!==null){
          setPrev(response.data[0]);
         
    
          if (width < 700){
            setPrevDate(moment(response.data[0].received_date).format('MM-DD'));

          }else{
            setPrevDate(moment(response.data[0].received_date).format('YYYY년 MM월 DD일 HH:mm:ss'));
          }
        }
        if (response.data[1]!==undefined){
          if (response.data[1].prev!==null){
            setNext(response.data[1]);
            setNextDate(moment(response.data[1].received_date).format('YYYY년 MM월 DD일 HH:mm:ss'));
            if (width < 700){
              setNextDate(moment(response.data[1].received_date).format('MM-DD'));

            }else{
              setNextDate(moment(response.data[1].received_date).format('YYYY년 MM월 DD일 HH:mm:ss'));
            }
          }
        }
      })
      .catch(function (error){
        console.log("erros...", error);
      })
      .then(function (){
        //always executed
      });
      console.log("no...", no, " folder...", folder);
     
        axios.get("https://gw.thegmmedical.com:5003/view/"+no+"/"+folder)
        .then(function (response){
        
          var message = response.data[0].html.replace(/(?:\r\n|\r|\n)/g, '<br>');
          setHtmlContent(message);
          let temp = response.data;
          if (!tryParseJSONObject(response.data[0].bcc)){
            temp[0].bcc = '[{"address": "'+response.data[0].bcc+'", "name":""}]'
          }
          
          setData(temp);console.log("data.data...", temp);
          console.log("parse........", JSON.parse(response.data[0].from_json))

          /*setPrev(response.data[0]["prev"]);
          setNext(response.data[0]["next"]);
          setPrevDate(response.data[0]["prev_date"]);
          setNextDate(response.data[0]["next_date"]);*/
        })
        .catch(function (error){
          console.log(error);
        })
        .then(function (){
          //always executed
        });
      
      
    /*fetch("https://gw.thegmmedical.com:5003/view/"+no+"/"+folder,  {
      method: "get", // 통신방법
      headers: {
        "content-type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((json) => {
        //setData(json[0].parsed);
        console.log(json);
      
        //setData(json.parsed);
        setData(json[0]["parsed"]);
        setPrev(json[0]["prev"]);
        setNext(json[0]["next"]);
       
        //setData(json);
        //console.log(json[0]["parsed"].attachments[0].filename);
      })
      .catch(() => {});*/
      axios.get('https://gw.thegmmedical.com:5003/api/getspam')
      .then(function (response){
        console.log("results.,.a,.fsf", response.data[0]);
        
          let adr = JSON.parse(response.data[0].email_addr);
          setAddrs(adr);
          console.log('adr....', adr);
      })
      .catch(function (error){
        console.log("erros...", error);
      })
      .then(function (){
        //always executed
      });
      
    }, [no]);

    function formatBytes(bytes, decimals = 2) {
      if (!+bytes) return '0'
  
      const k = 1024;
      const dm = decimals < 0 ? 0 : decimals;
      const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  
      const i = Math.floor(Math.log(bytes) / Math.log(k))
  
      return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
    }

    function movemails(){

      let arr = [];
      arr.push(data[0].uid);
  
      const msg ={
        nos : arr,
        folder : data[0].mailbox,
        dest : clicked
      }
      console.log("msg..................", msg);
      axios.post('https://gw.thegmmedical.com:5003/api/movemail', msg)
      .then(function (response){
        console.log(response);
        navigate("/list/INBOX");
      })
      .catch(function (error){
        console.log(error);
      })
      .then(function (){
        //always executed
      });
   
    }
    function delete_mail(){
      fetch("https://gw.thegmmedical.com:5003/api/deletemail/"+no+"/"+folder, {
        method: "POST", // 통신방법
      headers: {
        "content-type": "application/json",
      },
      credentials: "include"
    })
      .then((res) => res.json())
      .then((json) => {

        console.log(json);
        alert("삭제되었습니다.");

        navigate("/list/"+folder);
      })
      .catch(() => {});
    }
    function expunge_mail(){
      fetch("https://gw.thegmmedical.com:5003/api/expunge/"+no+"/"+folder, {
        method: "POST", // 통신방법
      headers: {
        "content-type": "application/json",
      },
      credentials: "include"
    })
      .then((res) => res.json())
      .then((json) => {
        console.log(json);
        alert("영구삭제되었습니다.");
        navigate("/list/"+folder);
        
      })
      .catch(() => {});
    }
 
  
    
    function addSpam(str){

      var valueArr = addrs.map(function(item){ return item.text });
      console.log("valueArr.....", valueArr, str);
      var isDuplicate = valueArr.some(function(item, idx){ 
        console.log("item....", JSON.stringify(item));
          return JSON.stringify(item).includes(str);
      });
      console.log("isdupplicate", isDuplicate);
      if (isDuplicate===false || addrs.length===0){
        let highestId;
        if (addrs.length===0){
          highestId=0;
        }else{
          highestId = Math.max.apply(Math, addrs.map(function(o) { return o.id; }))
        }
        
        let newId = 1; // default in case the array is empty
  
        if (highestId > 0) {
          // generate a new ID based off of the highest existing element ID 
          newId = (highestId + 1);
        }
  
        const item = { id: newId, text: str };
  
  
        const tempArray = [...addrs, item];
          
  
       
        const formdata = {
          keywords: "",
          domains : "",
          addrs : JSON.stringify(tempArray),
         
        }
        console.log("formdata......", formdata);
    
        axios.post('https://gw.thegmmedical.com:5003/api/block_addr', formdata)
        .then(function (response){
          alert("수신 차단되었습니다.");
        })
        .catch(function (error){
          console.log(error);
        })
        .then(function (){
          //always executed
        });
      }else{
        alert("이미 수신 차단한 주소입니다.")
      }
      
    };
    const togglePopup = (str) => {
      console.log(str);
      setActive(false);
      setAddr(str);
      setShow(!show);
    };
    function addAddr(link){
      const formdata = {
        keywords: "",
        domains : "",
        user_id : 1
      }
  
      axios.post('https://gw.thegmmedical.com:5003/api/add_addr', formdata)
      .then(function (response){
        console.log(response);
      })
      .catch(function (error){
        console.log(error);
      })
      .then(function (){
        //always executed
      });
    }

    function blockAddr(link){

      let item = { id: 1, text: link };
      let data = {
        keywords: "",
        domains : "",
        addrs : JSON.stringify(item),
      }
  var result = window.confirm("["+link+"] 을 수신차단 하시겠습니까?");
  if(result){
      axios.post('https://gw.thegmmedical.com:5003/api/block_addr', data)
      .then(function (response){
        console.log(response);
      })
      .catch(function (error){
        console.log(error);
      })
      .then(function (){
        //always executed
      });
    }
  }

  function unreadmails(){
    var array = []
    setUnread(false);
    array.push(no);
    
    
    const msg ={
      nos : array,
      folder:folder
    }
    axios.post('https://gw.thegmmedical.com:5003/api/unreadmails', msg)
    .then(function (response){
      console.log(response);
    })
    .catch(function (error){
      console.log(error);
    })
    .then(function (){
      //always executed
    });

  }

  function readmails(){
    var array = []
    setUnread(true);
    array.push(no);
    
    
    const msg ={
      nos : array,
      folder:folder
    }
    axios.post('https://gw.thegmmedical.com:5003/api/readmails', msg)
    .then(function (response){
      console.log(response);
    })
    .catch(function (error){
      console.log(error);
    })
    .then(function (){
      //always executed
    });

  }

  const handleCopyClipBoard = (text) => {
    try {
      navigator.clipboard.writeText(text);
      alert('클립보드에 복사되었습니다.');
    } catch (error) {
      alert('클립보드 복사에 실패하였습니다.');
    }
  };

  var body = document.getElementsByTagName("BODY")[0];

  if (window.addEventListener) {  // all browsers except IE before version 9
    window.addEventListener ("resize", onResizeEvent, true);
  } else {
    if (window.attachEvent) {   // IE before version 9
      window.attachEvent("onresize", onResizeEvent);
    }
  }
  
  function onResizeEvent() {
    body  = document.getElementsByTagName("BODY")[0];
    let width = body.offsetWidth;
    if (width < 700){
      setNextDate(moment(next.received_date).format('MM-DD'));
      setPrevDate(moment(prev.received_date).format('MM-DD'));

    }else{
      setNextDate(moment(next.received_date).format('YYYY년 MM월 DD일 HH:mm:ss'));
      setPrevDate(moment(prev.received_date).format('YYYY년 MM월 DD일 HH:mm:ss'));
    }
  }
  
  const componentRef = useRef();
  const formRef = useRef();
    const handlePrint = useReactToPrint({
      content: () => componentRef.current,
    });
    const formPrint = useReactToPrint({
      content: () => formRef.current,
    });
    function checkExtension(filename){
      var parts = filename.split('.');
      var extension = parts[parts.length-1];
      if (extension === "doc" || extension === "docx" || extension === "docm" || extension === "dot" || extension === "dotx"
      || extension === "odt" || extension === "xls" || extension === "xlsx" || extension === "xlsm"|| extension === "xlsb"
      || extension === "ppt"|| extension === "pptm"|| extension === "pptx"){
        return true;
      }else{
        return false;
      }
    }
  return data && (
    <div className="content-wrap">
    <div className="sidebar-icon" style={{margin:"0 auto"}} onClick={()=>{if (sidebar === false){document.getElementsByTagName("main")[0].style.overflow="hidden"}else{document.getElementsByTagName("main")[0].style.overflow="auto"} setSidebar(!sidebar);}}>
        <GiHamburgerMenu/>
      </div>
      {sidebar && (
        <div className="side-mobile-menu">
        <MailNav boxes={boxes}/>
        </div>
      )}
    <div className="outer_wrapper mail_view" style={{justifyContent:"flex-start", width:"100%", height:"100vh", margin:"0", minWidth:"100%"}}>
      <div className="side-menu">
      <MailNav boxes={boxes}/>
      </div>
      {show ? (
      <AddAddr addr = {addr} closePopup={() => {togglePopup();}} />
    ) : null}
    
    <div class="container">
    
    <Overlay isOpen={isOpen} onClose={toggleOverlay} link = {link}>
  <h1>Content in overlay</h1>
</Overlay>
    {move && (
          <div className="dropdown_div mobile" style={{padding:"20px"}}>
    
    <div className="list">
    
        
    <div className={clicked === "all" ? "box_item clicked" : "box_item"}  onClick={()=>setClicked("all")} ><li><span>받은편지함</span></li></div>
     <ul>
    
     {boxes.map((box, idx) => {
    
      if ((box.path).includes("INBOX.")){
       return <li className={clicked === "INBOX."+box.name ? "box_item clicked" : "box_item"} onClick={()=>setClicked("INBOX."+box.name)}>
      
         <span>- {box.name}</span>  
          
       </li>
      }})}
    
      </ul>
      df
      <div className={clicked === "sent" ? "box_item clicked" : "box_item"} onClick={()=>setClicked("sent")}><li><span>보낸편지함</span></li></div>
      <div className={clicked === "draft" ? "box_item clicked" : "box_item"}  onClick={()=>setClicked("draft")}><li><span>임시보관함</span></li></div>
      <div className={clicked === "Junk" ? "box_item clicked" : "box_item"}  onClick={()=>setClicked("Junk")}><li><span>스팸보관함</span></li></div>
    
    
    <a href="/list/delete" className={clicked === "delete" ? "box_item clicked" : "box_item"} ><li className="separated"><span>휴지통</span></li></a>
    </div>
            
              <button onClick={()=>movemails()}>이동</button>
            </div>
          )}   
    
    
    <div id="content">
      <div class="type_read">
        <div class="mail_toolbar_task">
        {folder !=="delete" && (
          <div class="button_group pc">
            <div class="button_task_wrap button_reply">
    
            <Link to="/reply" state={{ uid: no, data : data[0] }} >
              <button type="button" class="button_task"><span class="text">답장</span></button>
              </Link>
    
        
              
    
            </div>
            <div class="button_task_wrap button_reply_all">
            <Link to="/replyall" state={{ uid: no, data : data[0] }} >
              <button type="button" class="button_task"><span class="text">전체답장</span></button>
            </Link>
    
       
    
            </div>
            <div class="button_task_wrap button_forward">
            <Link to="/forward" state={{ uid: no, data : data[0] }} >
              <button type="button" class="button_task"><span class="text">전달</span></button>
              </Link>
            </div>
          </div>
          
          
          )}
    
          <div class="button_group mobile">
            <div class="button_task_wrap button_reply">
    
              <Link to="/reply" state={{ uid: no, data : data[0] }} >
              <button type="button" class="button_task"><span class="text"><BsReply/></span></button>
              </Link>
    
              
    
            </div>
            <div class="button_task_wrap button_reply_all">
         
    
            <Link to="/replyall" state={{ uid: no, data : data[0] }} >
              <button type="button" class="button_task"><span class="text"><BsReplyAllFill/></span></button>
            </Link>
    
            </div>
          
    
            <div class="button_task_wrap button_forward">
            {folder ==="delete" ? <div class="button_task_wrap">
              <button type="button" class="button_task delete_email" onClick={()=>expunge_mail()}><span class="text"><BsTrash3/></span></button>
            </div> : <button type="button" class="button_task delete_email" onClick={()=>delete_mail()}><span class="text"><BsTrash3/></span></button>}
            
            </div>
    
            <div class="button_task_wrap button_forward">
            <Link to="/forward" state={{ uid: no, data : data[0] }} >
              <button type="button" class="button_task"><span class="text">전달</span></button>
              </Link>
            </div>
            <div class="button_task_wrap">
            <button type="button" class="button_task_dropdown svg_move" onClick={()=>setMove(!move)}><span class="text">이동</span></button>
            </div>
            
            <div class="button_task_wrap">
            <a href="/list/all" style={{height:"100%"}}><button type="button" class="button_task_dropdown svg_move">
            <span class="text">목록</span></button></a>
            </div>
            
    
          </div>
          <div class="button_group pc">
            {folder !=="delete" && (<div class="button_task_wrap">
              <button type="button" class="button_task delete_email" onClick={()=>delete_mail()}><span class="text">삭제</span></button>
            </div>)}
            <div class="button_task_wrap">
              <button type="button" class="button_task delete_email" onClick={()=>expunge_mail()}><span class="text">영구삭제</span></button>
            </div>
            <div class="button_task_wrap "><button type="button" class="button_task svg_spam" onClick={()=>addSpam(data[0].from_addr)}><span class="text">스팸차단</span></button>
            </div>
            <div class="button_task_wrap">
              {unread ? <button type="button" class="button_task svg_unread" onClick={()=>unreadmails()}><span class="text">안읽음</span></button>
              : <button type="button" class="button_task svg_unread" onClick={()=>readmails()}><span class="text">읽음</span></button>}
              
            </div>
            
          </div>
          <div class="button_group pc">
            <div class="button_task_wrap">
              <button type="button" class="button_task_dropdown svg_move" onClick={()=>setMove(!move)}><span class="text">이동</span></button>
    
              {move && (
          <div className="dropdown_div" style={{padding:"20px"}}>
    
    <div className="list">
    
        
    <div className={clicked === "all" ? "box_item clicked" : "box_item"}  onClick={()=>setClicked("all")} ><li><span>받은편지함</span></li></div>
     <ul>
    
     {boxes.map((box, idx) => {
    
      if ((box.path).includes("INBOX.")){
       return <li className={clicked === "INBOX."+box.name ? "box_item clicked" : "box_item"} onClick={()=>setClicked("INBOX."+box.name)}>
      
         <span>- {box.name}</span>  
          
       </li>
      }})}
    
      </ul>
      
      <div className={clicked === "sent" ? "box_item clicked" : "box_item"} onClick={()=>setClicked("sent")}><li><span>보낸편지함</span></li></div>
      <div className={clicked === "Stars" ? "box_item clicked" : "box_item"} onClick={()=>setClicked("Stars")}><li><span>중요메일함</span></li></div>
      <div className={clicked === "draft" ? "box_item clicked" : "box_item"}  onClick={()=>setClicked("draft")}><li><span>임시보관함</span></li></div>
      <div className={clicked === "Junk" ? "box_item clicked" : "box_item"}  onClick={()=>setClicked("Junk")}><li><span>스팸보관함</span></li></div>
    
    
    <a href="/list/delete" className={clicked === "delete" ? "box_item clicked" : "box_item"} ><li className="separated"><span>휴지통</span></li></a>
    </div>
            
              <button onClick={()=>movemails()}>이동</button>
            </div>
          )}   
            </div>
            
          </div>
          <div class="button_task_wrap pc">
            <button>
            <span class="text"  onClick={handlePrint}>인쇄</span></button>
          </div>
          <div class="button_group pc">
            <div class="button_task_wrap">
            <a href="/list/all"><button type="button" class="button_list">목록</button></a>
            </div>
            
          </div>
        </div>
          {/*<button type="button" class="button_previous_mail svg_previous"><span class="blind">이전 메일 보기</span></button>
          <button type="button" class="button_next_mail svg_next"><span class="blind">다음 메일 보기</span></button>*/}
      </div>
      <div class="mail_view_wrap" ref={componentRef}>
        <div class="mail_view_header">
    
          <h3 class="">{data[0].subject}</h3>
          
    
          <div class="mail_from">
            <div className="from" style={{position:"relative", width:"auto", display:"flex"}}>
            <IoIosArrowDown className="pc" onClick={() => setToggle2(!toggle2)} style={{position:"absolute", marginLeft:"-20px"}}/>보낸 사람 : 
            
            
            <span class="date"
            onClick={()=>{setActive(!isActive); setCCActive(false); setToActive(false);  setBCCActive(false);}}>{(data[0].from_name!==undefined && data[0].from_name !==null) && data[0].from_name} {JSON.parse(data[0].from_json)[0].address} 
            
            </span>  
            <IoIosArrowDown className="mobile" style={{marginLeft:"5px"}} onClick={() => setToggle2(!toggle2)}/>
            
            {isActive && (
            <div>
              <div class="dropdown addr_card">
                <div className="search_dropdown_list">
    
                <div style={{display:"flex", justifyContent:"space-between"}}><span style={{color:"gray"}}>
                {data[0].from_name !=="" && (
                 <span> {'<'} {data[0].from_name} {'>'} </span>
                )}
                  {data[0].from_addr}
                  </span> 
      <span onClick={()=>{setActive(false);}}>X</span></div>
    
                <ul class="list">
                  <li class="item" onClick={()=>navigate("/write", {state: {to_addr: data[0].from_addr}})}>
                    <IoMailOutline/>
                    <button>메일 작성</button>
                  </li>
                  <li class="item" onClick={()=>{togglePopup(data[0].from_addr);setActive(false);}}>
                    <IoPersonAddOutline/>
                    <button>주소록추가</button>
                  </li> 
                  <li class="item" onClick={()=>{handleCopyClipBoard(data[0].from_addr)}}>
                    <IoCopyOutline/>
                    <button>주소 복사</button>
                  </li> 
                  <li class="item">
                    <BiBlock onClick={()=>{addSpam(data[0].from_addr)}}/>
                    <button>수신 차단</button>
                  </li>
                </ul>
             
               
              </div>
              </div>
            </div>
          )}
            </div>
    
            <span className="add_addr" onClick={()=>{togglePopup(data[0].from_addr);}}>주소 추가</span> <span className="block_addr" onClick={()=>{addSpam(data[0].from_addr)}}>수신 차단</span>
            
            
          </div>
          {toggle2 && (
            <div>
            <div class="mail_from">
              <div class="addr">받는 사람 : {(JSON.parse(data[0].to_addr)).map((ele, idx)=>(
                idx >0 ?  <div><span onClick={()=>{setToActive(!isToActive); setToAddr(ele.address);setCCActive(false); setActive(false);  setBCCActive(false);}}>, {ele.name !=="" && (
                  <span>{'<'}{ele.name}{'>'}</span>
                )} {ele.address} </span> </div>:  
                <div><span onClick={()=>{setToActive(!isToActive); setToAddr(ele.address); setCCActive(false); setActive(false);  setBCCActive(false);}}>
                  {ele.name !=="" && (
                  <span>{'<'}{ele.name}{'>'}</span>
                )}
                   {ele.address} </span>
               
                {isToActive && (
            <div>
              <div class="dropdown addr_card">
                <div className="search_dropdown_list">
                <div style={{display:"flex", justifyContent:"space-between"}}><span style={{color:"gray"}}>{toAddr}</span>
      <span onClick={()=>{setToActive(false);}}>X</span></div>
                <ul class="list">
                  <li class="item" onClick={()=>navigate("/write", {state: {to_addr: toAddr}})}>
                    <IoMailOutline/>
                    <button>메일 작성</button>
                  </li>
                  <li class="item" onClick={()=>{togglePopup([toAddr]);setToActive(false);}}>
                    <IoPersonAddOutline/>
                    <button>주소록추가</button>
                  </li>
                  <li class="item" onClick={()=>{handleCopyClipBoard(toAddr)}}>
                    <IoCopyOutline/>
                    <button>주소 복사</button>
                  </li>
                  <li class="item">
                    <BiBlock onClick={()=>{addSpam(toAddr)}}/>
                    <button>수신 차단</button>
                  </li>
                </ul>
             
               
              </div>
              </div>
            </div>
          )}
                </div>
               
               
               
              ))}





              <span className="add_addr" onClick={()=>{togglePopup((JSON.parse(data[0].to_addr)[0].address).toString().split(","));}}>주소 추가</span>
              
              </div>
            
              
              
            </div>
            {data[0].cc !=="" && data[0].cc !==null && (
    
    <div class="mail_from">
    <div class="addr">참조 : {(JSON.parse(data[0].cc)).map((ele, idx)=>(
      idx >0 ?  <div><span onClick={()=>{setCCActive(!isCCActive); setToActive(false); setActive(false);  setBCCActive(false);}}>,  {ele.address} </span> </div>:  
      <div><span onClick={()=>{setCCActive(!isCCActive); setToActive(false); setActive(false);  setBCCActive(false);}}> {ele.address} </span>
      
      {isCCActive && (
    <div>
    <div class="dropdown addr_card">
      <div className="search_dropdown_list">
      <div style={{display:"flex", justifyContent:"space-between"}}><span style={{color:"gray"}}>{ele.address}</span> 
      <span onClick={()=>{setCCActive(false);}}>X</span></div>
      <ul class="list">
        <li class="item" onClick={()=>navigate("/write", {state: {to_addr: ele.address}})}>
          <IoMailOutline/>
          <button>메일 작성</button>
        </li>
        <li class="item" onClick={()=>{togglePopup(JSON.parse(data[0].cc).map(function(item) {
              return item['address'];
            }))}}>
          <IoPersonAddOutline/>
          <button>주소록추가</button>
        </li> 
        <li class="item" onClick={()=>{handleCopyClipBoard(ele.address)}}>
          <IoCopyOutline/>
          <button>주소 복사</button>
        </li> 
        <li class="item">
          <BiBlock onClick={()=>{addSpam(ele.address)}}/>
          <button>수신 차단</button>
        </li>
      </ul>
    
     
    </div>
    </div>
    </div>
    )}
      </div>
      
      
     
    ))}
    
    <span className="add_addr" onClick={()=>{togglePopup(JSON.parse(data[0].cc).map(function(item) {
              return item['address'];
            }))}}>주소 추가</span>
    
    </div>
    
    
    
    </div>
    
           
            )}     
            {(data[0].bcc !=="" && data[0].bcc !==null && data[0].bcc.length !==0) && (
    
    <div class="mail_from">
    <div class="addr">숨은 참조 : {(JSON.parse(data[0].bcc)).map((ele, idx)=>(
      idx >0 ?  <div><span onClick={()=>{setBCCActive(!isBCCActive); setCCActive(false); setToActive(false);  setActive(false);}}>,  {ele.address} </span> </div>:  
      <div><span onClick={()=>{setBCCActive(!isBCCActive); setCCActive(false); setToActive(false);  setActive(false);}}> {ele.address} </span>
      
      {isBCCActive && (
    <div>
    <div class="dropdown addr_card">
      <div className="search_dropdown_list">
      <div style={{display:"flex", justifyContent:"space-between"}}><span style={{color:"gray"}}>{ele.address}</span> 
      <span onClick={()=>{setBCCActive(false);}}>X</span></div>
      <ul class="list">
        <li class="item" onClick={()=>navigate("/write", {state: {to_addr: ele.address}})}>
          <IoMailOutline/>
          <button>메일 작성</button>
        </li>
        <li class="item" onClick={()=>{togglePopup(JSON.parse(data[0].bcc).map(function(item) {
              return item['address'];
            }))}}>
          <IoPersonAddOutline/>
          <button>주소록추가</button>
        </li> 
        <li class="item" onClick={()=>{handleCopyClipBoard(ele.address)}}>
          <IoCopyOutline/>
          <button>주소 복사</button>
        </li> 
        <li class="item">
          <BiBlock onClick={()=>{addSpam(ele.address)}}/>
          <button>수신 차단</button>
        </li>
      </ul>
    
     
    </div>
    </div>
    </div>
    )}
      </div>
      
      
     
    ))}
    
    <span className="add_addr" onClick={()=>{togglePopup(JSON.parse(data[0].cc).map(function(item) {
              return item['address'];
            }))}}>주소 추가</span>
    
    </div>
    
    
    
    </div>
    
           
            )}     
          </div>
            )}
    
            
          <br></br>
          <div class="mail_date"><span class="date">
          {moment(data[0].received_date).format('YYYY년 MM월 DD일 HH:mm:ss')}</span></div>
    
          
        </div>
        <div class="mail_view_body">
          <div class="mail_view_contents">
    
           
    
            {/*} {attachments !=="" && <div>{attachments.content}</div>}
            {data.attachments[0].filename}
      {data.attachments[0].content}*/}
    
    {(data[0].filename !=="" && data[0].filename !==null && data[0].filename !==undefined) &&   
    <div className="attachment">
      <IoIosArrowDown onClick={() => setToggle(!toggle)} style={{cursor:"pointer"}}/><span>첨부파일 {data.length}개 </span>
      {toggle && (
    
      <div className="attachment_box">

      {data.map((element, idx) =>
        <div>{element.filename !==null && element.filename.slice(-4) ==="xlsx" && (<RiFileExcel2Line style={{color:"#1d6f42"}}/>)}
        {element.filename !==null && element.filename.slice(-4) ==="docx" && (<RiFileWord2Line style={{color:"#1B5EBE"}}/>)}
        <a href={"https://gw.thegmmedical.com/"+element.full_path} download>{element.filename} &nbsp;&nbsp;&nbsp;
        <span className="attachment_size">{formatBytes(element.size)} Bytes</span></a>
        {checkExtension(element.filename) && (
 <button style={{marginLeft:"10px"}} onClick={()=>{toggleOverlay(); setLink("https://gw.thegmmedical.com/"+element.full_path);}}>오피스뷰어</button>
        )}  
      </div>
       
      

   

        )}
 
        </div>)}
      </div>
    
      }
      
    
          <div style={{padding:"20px 0"}} dangerouslySetInnerHTML={ {__html: data[0].html} }>
          </div>
        
        </div>
      
      </div>
    
    </div>
    </div>
    
    
    <div className="bbsbotm_tbl">
      <div className="tbl_basic">
        <table>
                  <colgroup>
                    <col className="tit"></col>
                    <col></col>
                    <col className="day hide-m"></col>
                  </colgroup>
                  <tbody>
                  
                    {next.length !==0 && (
                      <tr>
                        <td>
                          <IoIosArrowUp/>
                        </td>
                        <td>
                        {next.from_name !== "" ? next.from_name : next.from_addr}
                        </td>
    
                        <td className="subject">
                          <a
                            href={"/"+folder+"/view/"+ next.uid}
                            className="t_dot"
                          >
                             
                            {next.subject}
                          </a>
                        </td>
                        <td className="hide-m">{nextDate}</td>
                      </tr>
                    )}
    
                    {prev.length !==0  && (
                      <tr>
                        <td>
                          <IoIosArrowDown/>
                        </td>
                        <td>
                        {prev.from_name !== "" ? prev.from_name : prev.from_addr}
                        </td>
                        <td className="subject">
                          <a
                            href={"/"+folder+"/view/"+ prev.uid}
                            className="t_dot"
                          >
                            {prev.subject}
                          </a>
                        </td>
                        <td className="hide-m">{prevDate}</td>
                      </tr>
                    )}
    
                  </tbody>
                </table>
              </div>
            </div>
      </div>
      </div>
        </div>
  );
};

export default View;
