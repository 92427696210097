import React, { useRef, useEffect, useState } from "react";
import { Link, useNavigate, useParams,useLocation } from "react-router-dom";
import { renderToString } from 'react-dom/server'
import DocList from "../../components/side/DocList";
import axios from "axios";
import moment from "moment";

// Toast 에디터
import { Editor } from '@toast-ui/react-editor';
import '@toast-ui/editor/dist/toastui-editor.css';
import 'tui-color-picker/dist/tui-color-picker.css';
import '@toast-ui/editor-plugin-color-syntax/dist/toastui-editor-plugin-color-syntax.css';
import colorSyntax from '@toast-ui/editor-plugin-color-syntax';
import DraftsList from "../../components/DraftsList";
import BoardList from "../../components/side/BoardList";
import { Tooltip } from "react-tooltip";
import { BiInfoCircle } from "react-icons/bi";
import "./css/expenditure.css";

//import LargeFile from "./largeFile";
axios.defaults.withCredentials = true; 



const Request = ({doc_no, onChange, line}) => {  
  

  const [links, setLinks]=useState([]);
  const [select, setSelect] = useState("");

  const [first, setFirst] = useState([]);
  const [second, setSecond] = useState([]);
  const [third, setThird] = useState([]);

  const [values, setValues] = useState([]);

  
 
 

  const handleInputChange = (e) => {
    //const name = e.target.name 
    //const value = e.target.value 
    const { name, value } = e.target;

    setValues({
      ...values,
      [name]: value,
    });
    onChange(values);

  };
 


  useEffect(() => {

    if (doc_no!==undefined){
    axios.get('http://gw.thegmmedical.com:5003/api/get_forms_data/expenditure/'+doc_no)
    .then(function (response){
      delete response.data[0].doc_no;
      delete response.data[0].no;
      onChange(response.data[0]);
      setValues(response.data[0]);
    
      
    })
    .catch(function (error){
      console.log(error);
    })
    .then(function (){
      //always executed
    });
    }
  }, []);



  window.addEventListener('message', (event) => {
    console.log(event.data);
    if (event.data["selectedMember"] !== undefined){
      
      var temp = event.data["selectedMember"];
      console.log("temp = ", temp);
      if (select === "담당"){
        setFirst(temp);
      }else if (select ==="검토"){
        setSecond(temp);
      }else if (select ==="승인"){
        setThird(temp);
      }
      
     
      
      
    }
   
  });


  return (
    <div className="wrapper">
    
    
      
      <div className="outer_wrapper" style={{justifyContent:"flex-start", width:"100%"}}>


      <div style={{width:"100%", margin:"50px auto"}}>     
     

    

            <div className="order_write forms" style={{padding:"0", margin:"20px auto"}}>
          

      <div className="rent">
        <div style={{fontFamily: '돋움', fontSize: '9pt', lineHeight: 'normal', marginTop: '0px', marginBottom: '0px'}}>
        <table style={{width:"100%", border:"none"}} className="headers-1">
                  <tr>
                    <td rowSpan={2} colSpan={8} className="title" style={{border:"none"}}>지출 요구서</td>
                   
                  </tr>
                
                </table>
                    <table className="expenditure" style={{fontFamily: 'malgun gothic,dotum,arial,tahoma', marginTop: '20px', borderCollapse: 'collapse',
                  width:"100%"}}>{/* User */} 
                   
                      <tbody>
                        <tr>
                          <th style={{padding: '5px', height: '18px', textAlign: 'center', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'bold', verticalAlign: 'middle'}}>
                            지출건명
                          </th>
                          <td style={{textAlign: 'left', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'normal', verticalAlign: 'middle'}}>
                            <input type="text" name="name" value={values.name} onChange={handleInputChange}></input>
                          </td>
                        </tr>
                        <tr>
                          <th style={{padding: '5px',  height: '18px', textAlign: 'center', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'bold', verticalAlign: 'middle'}}>
                            
                          </th>
                          <td style={{ textAlign: 'left', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'normal', verticalAlign: 'middle'}}>
                            <span>1.</span>
                            <input type="text" name="about" value={values.about} onChange={handleInputChange}></input>
                          </td>
                        </tr>
                        <tr>
                          <th style={{padding: '5px', height: '18px', textAlign: 'center', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'bold', verticalAlign: 'middle'}}>
                            
                          </th>
                          <td style={{textAlign: 'left', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'normal', verticalAlign: 'middle'}}>
                            <span>2.</span>
                            <input type="text" name="content" value={values.content} onChange={handleInputChange} style={{width:"100px"}}></input>
                          </td>

                        </tr>
                        <tr>
                          <th style={{padding: '5px', height: '18px', textAlign: 'center', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'bold', verticalAlign: 'middle'}}>
                            
                          </th>
                          <td style={{textAlign: 'left', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'normal', verticalAlign: 'middle'}}>
                            <span>3. 지출 내역</span>    
                          </td>
                          
                        </tr>
                        <tr>
                          <th style={{padding: '5px', height: '18px', textAlign: 'center', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'bold', verticalAlign: 'middle'}}>
                            
                          </th>
                          <td style={{textAlign: 'left', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'normal', verticalAlign: 'middle'}}>
                            <span>가. 금액 : </span> 
                            <input type="text" name="amount" value={values.amount} onChange={handleInputChange}></input>
                          </td>

                        </tr>
                         
                        <tr>
                          <th style={{padding: '5px', height: '18px', textAlign: 'center', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'bold', verticalAlign: 'middle'}}>
                            
                          </th>
                          <td style={{textAlign: 'left', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'normal', verticalAlign: 'middle'}}>
                            <span>지출처 : </span> 
                            <input type="text" name="payTo" value={values.payTo} onChange={handleInputChange}></input>
                          </td>


                         
                        </tr>
                        <tr>
                          <th style={{padding: '5px', height: '18px', textAlign: 'center', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'bold', verticalAlign: 'middle'}}>
                            
                          </th>
                          <td style={{textAlign: 'left', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'normal', verticalAlign: 'middle'}}>
                          <span>지출일자 : </span> 
                          <input type="date" name="pay_date" value={values.pay_date} onChange={handleInputChange}
                          style={{width:"100px"}}></input>
                          </td>


                         
                        </tr>
                       
                       <tr>

                       </tr>
                       <tr>
                        
                       </tr>
                       <tr>
                        
                       </tr>
                       <tr>
                        
                       </tr>
                        <tr>
                          <th style={{padding: '5px', height: '18px', textAlign: 'center', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'bold', verticalAlign: 'middle'}}>
                            붙임 :
                          </th>
                          <td style={{textAlign: 'left', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'normal', verticalAlign: 'middle'}}>
                          <input type="text" name="memo" value={values.memo} onChange={handleInputChange}></input>
                          </td>
                        </tr>

                      </tbody>
                    </table>

                    <p style={{width:"100%", textAlign:"center"}}>{new Date().getFullYear() + "년 " + ('0' + (new Date().getMonth() + 1)).slice(-2) +
"월 " + ('0' + new Date().getDate()).slice(-2) + "일" }</p>
              
                    <table style={{width:"100%", float:"right", border:"none"}} className="headers-1">
                  <tr>
                    <td rowSpan={2} colSpan={9} className="title" style={{border:"none"}}></td>
                    <td rowSpan={2} className="approve">요구 부서</td>
                    <td className="approve">담당</td>
                    <td className="approve">차장</td>
                    <td className="approve">부서장</td>
                  </tr>
                  <tr>
                  <td>
                  </td>
                  <td>

                  </td>
                    <td>
           
                    </td>

               
                  </tr>
                </table>
              

      </div>

      </div>
      
     <div id="response">{links}</div>
 
      <div className="button_wrap">
      {/*</form>*/}
      </div>
      </div>
    </div>
    </div>
    </div>

  );
};

export default Request;

